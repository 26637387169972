import {dom} from 'core-utils';
import {scsController} from '../stockcars-bundle';
import {scPage} from '../stockcars-utils-bundle';
import {STCK_STORE, SELECTORS} from '@oneaudi/stck-store';

export default class ScDetailsServicebarElement
	extends HTMLElement {
	constructor() {
		super();
	}

	static get defaults() {
		return {
			'classNewSearch': '.sc-tb .nm-icon-search-new',
			'classDetailContainer': '.sc-detail-module',
			'classDetailCarInfo': '.sc-detail-carinfo',
			'classFavorite': '.js-favorite',
			'classFavoriteToolbarCount': '.sc-module-servicebar .nm-icon-favorites .sc-tb-linkcount',
			'classSbAudiCode': '.sc-sb-audicode',
			'classAudiCodeFlyout': '.sc-sb-audicode-flyout'
		};
	}

	connectedCallback() {
		this._favoriteCountElement = dom.getElement(ScDetailsServicebarElement.defaults.classFavoriteToolbarCount, this);
		this.toggleElem = dom.getElement(ScDetailsServicebarElement.defaults.classSbAudiCode, this);
		this._newSearchButton = dom.getElement(ScDetailsServicebarElement.defaults.classNewSearch, this);
		this.wrapper = dom.closest(this, '.nm-wrapper');
		this.layerWrapper = dom.closest(this, '.nm-layer-wrapper');
		this._addEvents();
		this._setFavoriteButtonServiceBarActive();
		this._setFavoriteCountInToolbar();

	}

	disconnectedCallback() {
		this._removeEvents();
	}

	/**
	 * setFavoriteButtonServiceBarInActive
	 * @returns {void} returns nothing
	 */
	_setFavoriteButtonServiceBarActive() {
		const favoriteButton = dom.getElement(ScDetailsServicebarElement.defaults.classFavorite, this);
		const vehicleId = favoriteButton.getAttribute('data-vehicle-id');
		if (SELECTORS.VEHICLES.isFavoriteVehicle(STCK_STORE.state, vehicleId)) {
			favoriteButton.classList.add('active');
		}
		else {
			favoriteButton.classList.remove('active');
		}
	}

	/**
	 * set all favorites
	 * @method _setFavoriteCountInToolbar
	 * @returns {void} returns nothing
	 */
	_setFavoriteCountInToolbar() {
		this._favoriteCountElement.innerHTML = `(${SELECTORS.VEHICLES.getFavoriteVehicleIdsState(STCK_STORE.state).length})`;
	}

	/**
	 * toggle the audicode flyout
	 * @param {Event} e click event
	 * @returns {void}
	 */
	_toggleFlyout(e) {
		const targetItem = e.target;
		const withinFlyout = dom.closest(targetItem, '.sc-sb-audicode-flyout');
		if (!withinFlyout) {
			this.toggleElem.classList.toggle('sc-sb-audicode-flyout-active');
		}
	}

	/**
	 * hide the audicode flyout in case of click besides
	 * (must not operate, when clicked within audicode link)
	 * @param {Event} e - the event object
	 * @returns {void}
	 */
	_hideFlyoutOnSideClick(e) {
		const withinFlyout = dom.closest(e.target, ScDetailsServicebarElement.defaults.classSbAudiCode);
		if (!withinFlyout) {
			this.toggleElem.classList.remove('sc-sb-audicode-flyout-active');
		}
	}

	/**
	 * newSearchClickHandler
	 * @param {event} event clickEvent
	 * @return {void} returns nothing
	 */
	async _newSearchClickHandler(event) {
		event.preventDefault();
		const element = dom.closest(event.target, 'a');

		if (element) {
			await scsController.clearAllFilters();
			if (scPage.isDetailsPage(location.href)) {
				const href = element.getAttribute('href');
				scPage.simulateNmPageOpen(href);
			}
		}
	}

	/**
	 * add events
	 * @returns {void}
	 */
	_addEvents() {
		this.update = this.update.bind(this);
		this._toggleFlyout = this._toggleFlyout.bind(this);
		this._hideFlyoutOnSideClick = this._hideFlyoutOnSideClick.bind(this);
		this._newSearchClickHandler = this._newSearchClickHandler.bind(this);

		this.unsucscibeFromFavoriteState = STCK_STORE.observeStateFromStore(SELECTORS.VEHICLES.getFavoriteVehicleIdsState, this.update);
		this._newSearchButton.addEventListener('click', this._newSearchClickHandler);
		this.toggleElem.addEventListener('click', this._toggleFlyout);
		if (this.wrapper) {
			this.wrapper.addEventListener('click', this._hideFlyoutOnSideClick);
		}
		if (this.layerWrapper) {
			this.layerWrapper.addEventListener('click', this._hideFlyoutOnSideClick);
		}
	}

	/**
	 * remove events
	 * @returns {void}
	 */
	_removeEvents() {
		this.unsucscibeFromFavoriteState();
		this._newSearchButton.removeEventListener('click', this._newSearchClickHandler);
		this.toggleElem.removeEventListener('click', this._toggleFlyout);
		if (this.wrapper) {
			this.wrapper.removeEventListener('click', this._hideFlyoutOnSideClick);
		}
		if (this.layerWrapper) {
			this.layerWrapper.removeEventListener('click', this._hideFlyoutOnSideClick);
		}
	}

	/**
	 * update - scs model base update callback function
	 * @returns {void} void
	 */
	update() {
		this._setFavoriteCountInToolbar();
	}
}

if (window.customElements.get('sc-details-servicebar-element') === undefined) {
	window.customElements.define('sc-details-servicebar-element', ScDetailsServicebarElement);
}

