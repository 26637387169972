import {STCK_STORE, SELECTORS} from '@oneaudi/stck-store';
import {scPage} from '../../stockcars-utils-bundle';

export class AozElement extends HTMLElement {
	constructor() {
		super();
	}

	static get defaults() {
		return {
			selectorShowMore: '.sc-aoz-showmore',
			selectorEquipmentAddButton: '.sc-details-aoz-dealer-button',
			selectorEquipmentAddButtonCount: '.sc-aoz-equipment-add-count'
		};
	}

	connectedCallback() {
		this._initializeClassVariables();
		this._addEvents();
		this._updateEquipmentAddButtonCount();
	}

	disconnectedCallback() {
		this._removeEvents();
	}

	_initializeClassVariables() {
		this._vehicleId = scPage.getVehicleIdFromUrl();
		this._equipmentAddButton = this.querySelector(AozElement.defaults.selectorEquipmentAddButton);
		this._equipmentAddButtonCount = this._equipmentAddButton.querySelector(AozElement.defaults.selectorEquipmentAddButtonCount);
	}

	_addEvents() {
		this.unsubscribeAozState = STCK_STORE.observeStateFromStore(SELECTORS.AOZ.getAozStateRaw, this._updateEquipmentAddButtonCount.bind(this));
	}

	_removeEvents() {
		this.unsubscribeAozState();
	}

	_updateEquipmentAddButtonCount() {
		const vehicleAozState = SELECTORS.AOZ.getAddedProductsByVehicleId(STCK_STORE.state, this._vehicleId);
		this._equipmentAddButtonCount.innerHTML = vehicleAozState.length;

		if (vehicleAozState.length) {
			this._equipmentAddButton.dataset.state = 'active';
		}
		else {
			this._equipmentAddButton.dataset.state = 'inactive';
		}
	}
}

if (window.customElements.get('aoz-element') === undefined) {
	window.customElements.define('aoz-element', AozElement);
}
