import LiteReservationRequestController from './lite-reservation-request-controller';
import {vehiclesController} from '../../stockcars-bundle';

export default class LiteReservationButtonElement extends HTMLElement {
	constructor() {
		super();
		this.windowReference = null;

	}

	/**
	 * connectedCallback
	 * @returns {void} void
	 */
	connectedCallback() {
		this.addEventListener('click', this._clickHandler.bind(this));
	}

	/**
	 * disconnectedCallback
	 * @returns {void} void
	 */
	disconnectedCallback() {
		this.removeEventListener('click', this._clickHandler);
	}

	/**
	 * _getPdfUrl
	 * @param {String} url_ ecom url for PDF
	 * @returns {string} pdf url with protocol from location
	 * @private
	 */
	_getPdfUrl(url_ = '') {
		const protocol = location.protocol || '';
		const pdfUrl = url_.replace('/stck/', '/audicode/ecom/');
		return protocol + pdfUrl;
	}

	/**
	 * click handler -
	 * @returns {Promise<void>} void
	 * @private
	 */
	async _clickHandler() {
		this.windowReference = window.open('', '_blank');
		const baseLiteReservationUrl = SETUPS.get('stockcar.lite.reservation.url');
		const postOptions = await this._getPostOptions();

		const liteReservationUrl = await LiteReservationRequestController.fetchLiteReservationUrl(baseLiteReservationUrl, postOptions).catch(error => {
			console.error(error);
			this.windowReference.close();
			this._disableReservationButton();
		});

		if (!!liteReservationUrl) {
			this._openLiteReservationInNewTab(liteReservationUrl);
		}
		else {
			this.windowReference.close();
			this._disableReservationButton();
		}
	}

	/**
	 * _disableReservationButton
	 * @private
	 * @returns {void} void
	 */
	_disableReservationButton() {
		this.setAttribute('data-state', 'inactive');
	}

	/**
	 * _openLiteReservationInNewTab
	 * @param {string} liteReservationUrl liteReservationUrl
	 * @private
	 * @returns {void} void
	 */
	_openLiteReservationInNewTab(liteReservationUrl = '') {
		this.windowReference.location.href = liteReservationUrl;
	}

	/**
	 * _getPostOptions
	 * @returns {object} postOptions
	 * @private
	 */
	async _getPostOptions() {
		const {vehicleId = '', pdfUrl = ''} = this.dataset;
		const vehicle = await vehiclesController.loadVehicleById(vehicleId);
		const ecomPdfUrl = this._getPdfUrl(pdfUrl);

		return {
			pdfUrl: ecomPdfUrl,
			vehicle
		};
	}
}

if (window.customElements.get('lite-reservation-button-element') === undefined) {
	window.customElements.define('lite-reservation-button-element', LiteReservationButtonElement);
}
