import {vehiclesController} from '../stockcars-bundle';
import {OBJECT_UTILS, TECHDATA_UTILS} from '../stockcars-utils-bundle';

export default class ScDetailsHighlightsElement extends HTMLElement {

	static get defaults() {
		return {
			classHighlight: '.sc-detail-highlight',
			classHighlightLabel: '.sc-detail-highlight-label',
			classHighlightDescription: '.sc-detail-highlight-description'
		};
	}

	connectedCallback() {
		this._doCustomModifications();
	}

	/**
	 * @method _doCustomModifications
	 * @returns {void}
	 * @private
	 */
	// eslint-disable-next-line max-statements
	async _doCustomModifications() {
		const highlights = this.querySelectorAll(ScDetailsHighlightsElement.defaults.classHighlight);
		const language = SETUPS.get('nemo.locale.language');
		for (let i = 0; i < highlights.length; i++) {
			const highlight = highlights[i];
			const tecKey = highlight.getAttribute('data-tec-key');
			const label = highlight.querySelector(ScDetailsHighlightsElement.defaults.classHighlightLabel);
			const description = highlight.querySelector(ScDetailsHighlightsElement.defaults.classHighlightDescription);
			if ((tecKey === 'ConsumptionDisplay') || (tecKey === 'ConsumptionDisplayElectrical')) {
				this._setConsumptionDetailsLink(highlight);
				this._setConsumptionPhevData(highlight, location.href);
			}
			else if (tecKey === 'AvailableFromDate') {
				this._interpretAvailableFromDate(highlight, label, description, language);
			}
			else if (tecKey === 'PowerDisplay') {
				this._addEnginePowerBoostFootnote(location.href, tecKey, description);
			}
			else {
				if (!!description) {
					this._addEllipsisAtSecondLine(description);
				}
			}
			if (!!description) {
				description.classList.remove('hidden');
			}
		}
	}

	/**
	 * @method _getVehicleIdFromUrl
	 * @param {string} url url
	 * @returns {string} returns only the last id (needed for detail page in layer on top of detail page)
	 * @private
	 */
	_getVehicleIdFromUrl(url) {
		const regex = /(sc_detail.*?\.)(([^.\s])+)/gi;
		let vehicleIds = [];
		let matchesArr;

		while ((matchesArr = regex.exec(url))) {
			vehicleIds.push(matchesArr[2]);
		}

		return vehicleIds[vehicleIds.length - 1] || '';
	}

	/**
	 * @method _setConsumptionDetailsLink
	 * @param {object} highlight highlight
	 * @returns {void}
	 * @private
	 */
	_setConsumptionDetailsLink(highlight) {
		let vehicleId = this._getVehicleIdFromUrl(location.href);
		highlight.lastElementChild.setAttribute('data-id', (vehicleId ? vehicleId : ''));
	}

	/**
	 * _setConsumptionPhevData
	 * @param {HTMLElement} highlight highlight
	 * @param {String} href href
	 * @returns {Promise<void>} void void
	 * @private
	 */
	async _setConsumptionPhevData(highlight, href) {
		const vehicleId = this._getVehicleIdFromUrl(href);
		const vehicleData = await vehiclesController.loadVehicleById(vehicleId).catch(error => console.error(error));

		if (vehicleData && Object.keys(vehicleData).length && OBJECT_UTILS.getNestedObject(vehicleData, ['io', 'engineType']) && vehicleData.io.engineType === 'OVC_HEV') {
			const highlightDescription = [...highlight.children].find(child => child.matches('.sc-detail-highlight-description'));
			if (highlightDescription) {
				const consumptionLabel = this._getPhevConsumptionLabel(vehicleData);
				if (!!consumptionLabel) {
					highlightDescription.innerHTML = consumptionLabel;
				}
			}
		}
	}

	/**
	 * _getPhevConsumptionLabel
	 * @param {Object} vehicleData vehicleData
	 * @returns {*} prepared PHEC consumption label | void
	 * @private
	 */
	_getPhevConsumptionLabel(vehicleData = {}) {
		const petrolConsumption = vehicleData.io.fuels.find(fuelType => fuelType.fuel === 'PETROL');
		const electricalConsumption = vehicleData.io.fuels.find(fuelType => fuelType.fuel === 'ELECTRICAL');

		if (petrolConsumption && electricalConsumption) {
			const petrolNedcValues = OBJECT_UTILS.getNestedObject(petrolConsumption, ['consumption', 'nedc']);
			const electricalNedcValues = OBJECT_UTILS.getNestedObject(electricalConsumption, ['consumption', 'nedc']);

			if (petrolNedcValues && electricalNedcValues) {
				return this._getPreparedPhevConsumptionLabel(
					{
						type: 'PETROL', values: petrolNedcValues
					},
					{
						type: 'ELECTRICAL', values: electricalNedcValues
					});
			}
		}
	}

	/**
	 * _getPreparedPhevConsumptionLabel
	 * @param {Object} petrolNedcValues petrolNedcValues
	 * @param {Object} electricalNedcValues electricalNedcValues
	 * @returns {String} Prepared PHEV Consumption Label
	 * @private
	 */
	_getPreparedPhevConsumptionLabel(petrolNedcValues, electricalNedcValues) {
		return [petrolNedcValues, electricalNedcValues].reduce((accumulator, currentFuelType) => {
			const type = currentFuelType.type;
			const consolidatedValue = currentFuelType.values.consolidated;
			const consumptionUnit = currentFuelType.values.unit;

			if (consolidatedValue && consumptionUnit) {
				return accumulator + window.i18n[`sc.details.phev.highlights.${type.toLowerCase()}.label`] + ` ${consolidatedValue} ${consumptionUnit}</br>`;
			}
			return accumulator;
		}, '');
	}

	/**
	 * @method _interpretAvailableFromDate
	 * @param {object} highlight highlight
	 * @param {object} label label
	 * @param {object} description description
	 * @param {string} language language
	 * @returns {void}
	 * @private
	 */
	_interpretAvailableFromDate(highlight, label, description, language) {
		const timestamp = highlight.getAttribute('data-timestamp');
		if (new Date().getTime() - Number(timestamp) < 0) {
			label.firstElementChild.innerHTML = window.i18n['sc.highlights.availablefromdate.available.later.label'] || 'sc.highlights.availablefromdate.available.later.label';
			description.innerHTML = this._getFormattedDate(timestamp, language);
		}
		else {
			label.firstElementChild.innerHTML = window.i18n['sc.highlights.availablefromdate.available.now.label'] || 'sc.highlights.availablefromdate.available.now.label';
			description.innerHTML = window.i18n['sc.highlights.availablefromdate.available.now.description'] || 'sc.highlights.availablefromdate.available.now.description';
		}
	}

	/**
	 * @method _addEnginePowerBoostFootnote
	 * @param {string} href href
	 * @param {string} techDataKey techDataKey
	 * @param {object} description description
	 * @returns {void}
	 * @private
	 */
	async _addEnginePowerBoostFootnote(href, techDataKey, description) {
		const vehicleId = this._getVehicleIdFromUrl(href);
		const vehicle = await vehiclesController.loadVehicleById(vehicleId).catch(error => console.error(error));
		description.innerHTML = vehicle ? TECHDATA_UTILS.checkForEnginePowerBoostFootnote({vehicle, techDataKey, techDataValue: description.innerHTML}) : description.innerHTML;
	}

	/**
	 * @method _getFormattedDate
	 * @param {string} timestamp_ date timestamp string
	 * @param {string} language_ locale language string
	 * @returns {string} formattedDate - return formattedDate string
	 * @private
	 */
	_getFormattedDate(timestamp_, language_) {
		const date = new Date(Number(timestamp_));
		let formattedDate = date.toLocaleDateString(language_) === 'Invalid Date' ? '' : date.toLocaleDateString(language_);
		if (date instanceof Date && !isNaN(date.getTime())) {
			const datePattern = (SETUPS.get('stockcar.custom.date.pattern') || '').toLowerCase();
			if (!!datePattern) {
				let dd = date.getDate(),
					mm = date.getMonth() + 1;
				const yyyy = date.getFullYear();
				dd = (dd < 10) ? `0${dd}` : dd;
				mm = (mm < 10) ? `0${mm}` : mm;
				formattedDate = datePattern.replace('dd', dd).replace('mm', mm).replace('yyyy', yyyy);
			}
		}
		return formattedDate;
	}

	/**
	 * @method _addEllipsisAtSecondLine
	 * @param {object} description description
	 * @returns {void}
	 * @private
	 */
	_addEllipsisAtSecondLine(description) {
		const descriptionWord = description.innerHTML.split(' ');
		while ((description.scrollHeight > description.offsetHeight) && (descriptionWord.length > 1)) {
			descriptionWord.pop();
			description.innerHTML = descriptionWord.join(' ') + '...';
		}
	}
}

if (window.customElements.get('sc-details-highlights-element') === undefined) {
	window.customElements.define('sc-details-highlights-element', ScDetailsHighlightsElement);
}
