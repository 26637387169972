import {patternMatching} from 'core-global';
import {scPage} from '../../stockcars-utils-bundle';
import {BaseButtonLogic} from "../../stockcars/buttons/sc-base-button-logic";

export default class DealerVtpLinkElement extends BaseButtonLogic {
	constructor() {
		super();
	}

	async connectedCallback() {
		await this._initialize();
		this._handleDealerPattern();
	}

	/**
	 * handleDealerPattern
	 * @return {void} returns nothing
	 */
	_handleDealerPattern() {
		const dealerLink = this.querySelector('.sc-js-dealerVtp');
		if (dealerLink) {
			setTimeout(async () => {
				const replacedUrl = await patternMatching.getReplacedUrl(dealerLink.href, '');
				const presetPreparedUrl = replacedUrl.replace(/(preset=)(\d*)/, 'preset=dealer.$2');
				const filterPreparedUrl = presetPreparedUrl.replace(/filter=filter/, 'filter');
				dealerLink.href = scPage.getMergedGetParamsUrl(decodeURIComponent(filterPreparedUrl));
				if (dealerLink.dataset.patternUrl) {
					dealerLink.patternUrl = dealerLink.dataset.patternUrl;
					await this._patchPatternInSingleUrl(dealerLink);
					dealerLink.href = dealerLink.patternUrl;
				}
			}, 100); // timeout to prevent timing issue with connected callback and pattern-matching
		}
	}
}

if (window.customElements.get('dealer-vtp-link-element') === undefined) {
	window.customElements.define('dealer-vtp-link-element', DealerVtpLinkElement);
}
