const exports = `
		<div class="modal-layer__inner">
			<div class="sc-module-grid-stage sc-grid-stage-modal-layer" {{? it.marketType === 'U'}}data-theme="dark"{{?}} data-layer-options='{"addCssClass": "sc-grid-stage-modal-layer-options"}'>
				<div class="sc-grid-stage-layer-close">
					<svg class="nm-icon-cancel sc-grid-stage-layer-close-icon">
						<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/cancel.svg#nm-icon-cancel"}}></use>
					</svg>
				</div>
				<div class="modal-layer__inner-content">
					<div class="sc-grid-stage-tabs-layer{{? (!it.threeSixtyUrl || !it.hasCarImages)}} sc-grid-stage-no-tabs{{?}}">

						<input class="sc-grid-stage-tab-selector sc-grid-stage-tab-1" type="radio" id="sc-grid-stage-tab-1-layer" name="sc-grid-stage-inputs-layer" {{? !it.showThreesixty}}checked{{?}}>
						<input class="sc-grid-stage-tab-selector sc-grid-stage-tab-2" type="radio" id="sc-grid-stage-tab-2-layer" name="sc-grid-stage-inputs-layer" {{? it.showThreesixty}}checked{{?}}>
						<div class="sc-grid-stage-tab-labels audi-copy-m">
							<label class="sc-grid-stage-tab-label sc-grid-stage-tab-1" for="sc-grid-stage-tab-1-layer">{{=window.i18n['sc.grid-stage-tab-gallery']||'sc.grid-stage-tab-gallery'}}</label>
							<label class="sc-grid-stage-tab-label sc-grid-stage-tab-2" for="sc-grid-stage-tab-2-layer">{{=window.i18n['sc.grid-stage-tab-360']||'sc.grid-stage-tab-360'}}</label>
						</div>

						<div class="sc-grid-stage-tab-elements">

							<swipe-gallery-with-thumbnails-element class="sc-grid-stage-gallery-container swipe-gallery-element swipe-gallery-with-thumbnails-element" data-index="{{=it.index}}" data-enable-scroll-swipe="true">
								<div class="sc-grid-stage-gallery sc-j-swipe-gallery-container">
									<div class="sc-grid-stage-gallery-slider-wrapper sc-j-swipe-gallery-slider-wrapper">
										<div class="sc-grid-stage-gallery-slider sc-j-swipe-gallery-slider">
											{{~ it.urls :pictureUrl :index}}
												{{? index > 1}}
													<div class="sc-grid-stage-gallery-slide sc-j-swipe-gallery-slide">
														<div class="sc-j-swipe-gallery-slide-item">
															<div class="sc-j-swipe-gallery-slide-item-image-sizer">
																<div class="sc-j-swipe-gallery-slide-item-image-wrapper sc-j-swipe-gallery-lazy">
																	<img class="sc-grid-stage-gallery-slide-content sc-j-swipe-gallery-slide-item-image" draggable="false"
																		 data-src="{{=pictureUrl}}"
																		 data-index="{{=index}}" />
																	<div class="sc-j-swipe-gallery-lazy-loader"></div>
																</div>
															</div>
														</div>
													</div>
												{{??}}
													<div class="sc-grid-stage-gallery-slide sc-j-swipe-gallery-slide">
														<div class="sc-j-swipe-gallery-slide-item">
															<div class="sc-j-swipe-gallery-slide-item-image-sizer">
																<div class="sc-j-swipe-gallery-slide-item-image-wrapper">
																	<img class="sc-grid-stage-gallery-slide-content sc-j-swipe-gallery-slide-item-image" draggable="false" src="{{=pictureUrl}}" data-index="{{=index}}" />
																</div>
															</div>
														</div>
													</div>
												{{?}}
											{{~}}
										</div>
									</div>
									{{? it.urls.length > 1 }}
										<div class="sc-grid-stage-gallery-next-wrapper">
											<div class="sc-grid-stage-gallery-next sc-j-swipe-gallery-navigation-next" tabindex="0" role="button" aria-label="Previous slide" aria-disabled="false">
												<svg class="nm-icon-forward-large">
													<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/forward-large.svg#nm-icon-forward-large"}}></use>
												</svg>
											</div>
										</div>
										<div class="sc-grid-stage-gallery-prev-wrapper">
											<div class="sc-grid-stage-gallery-prev sc-j-swipe-gallery-navigation-prev" tabindex="0" role="button" aria-label="Next slide" aria-disabled="false">
												<svg class="nm-icon-back-large">
													<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/back-large.svg#nm-icon-back-large"}}></use>
												</svg>
											</div>
										</div>
									{{?}}
								</div>

								{{? it.urls.length > 1 }}
									<div class="sc-grid-stage-gallery-thumbnails">
										<div class="sc-grid-stage-gallery-thumbnails-slider-wrapper sc-j-swipe-gallery-thumbnails-slider-wrapper">
											<div class="sc-grid-stage-gallery-thumbnails-slider sc-j-swipe-gallery-thumbnails-slider sc-j-swipe-gallery-thumbnails-animate">
												{{~ it.urls :pictureUrl :index}}
													<div class="sc-grid-stage-gallery-thumbnails-slide sc-j-swipe-gallery-thumbnails-slide"  data-index="{{=index}}" draggable="false">
														<div class="sc-j-swipe-gallery-thumbnails-slide-sizer">
															<div class="sc-grid-stage-gallery-thumbnails-slide-content-wrapper sc-j-swipe-gallery-thumbnails-slide-content-wrapper" style="background-image: url({{=pictureUrl}}?width=320;"></div>
														</div>
													</div>
												{{~}}
											</div>
										</div>
										<div class="sc-grid-stage-gallery-thumbnails-prev-wrapper">
											<div class="sc-grid-stage-gallery-thumbnails-prev sc-j-swipe-gallery-thumbnail-navigation-prev" tabindex="0" role="button" aria-label="Next slide" aria-disabled="false">
												<svg class="audi-link-s__icon">
													<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/forward-small.svg#audi-icon-forward-small"}}></use>
												</svg>
											</div>
										</div>
										<div class="sc-grid-stage-gallery-thumbnails-next-wrapper">
											<div class="sc-grid-stage-gallery-thumbnails-next sc-j-swipe-gallery-thumbnail-navigation-next" tabindex="0" role="button" aria-label="Previous slide" aria-disabled="false">
												<svg class="audi-link-s__icon">
													<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/forward-small.svg#audi-icon-forward-small"}}></use>
												</svg>
											</div>
										</div>
									</div>
								{{?}}
							</swipe-gallery-with-thumbnails-element>
							<div class="sc-grid-stage-360-container">
								<div class="sc-grid-stage-360-sizer">
									<div class="sc-grid-stage-360-ratio">
										<iframe class="sc-grid-stage-360-iframe" src="{{=it.threeSixtyUrl}}" allowfullscreen></iframe>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
`;

export {exports as gridStageLayerTemplate};
