import {dom} from 'core-utils';
import {CoreEvents} from '@audi/audi-core-events';
import {globalEventEmitter} from "../stockcars-bundle";

var __ = {},
	exports = {
		'__': __
	};

__.oDefaults = {
	iDefaultRadix: 10,
	sClassAnimL2r: 'ucl2r',
	sClassAnimR2l: 'ucr2l',
	sSelectorStageImageWrapper: '.stage-uc-image-wrapper',
	sSelectorStageItem: '.stage-uc-item',
	sSelectorStageNaviPrev: '.stage-uc-navi-prev',
	sSelectorStageNaviNext: '.stage-uc-navi-next',
	sSelectorImageSource: '.stage-uc-imagesource',
	selectorStageImageBegin: '.sc-module[data-module="details-stage-uc"] .stage-uc-item-',
	selectorStageImageEnd: ' .stage-uc-image.active img'
};

__.globalEventBus = globalEventEmitter;
__.domDelegate = dom.getEventDelegate('body');

__.initStageModules = function (data) { // eslint-disable-line max-statements
	var context, stageModulesOnPage, newStage, stageId, i;

	if (!!data && !!data.domElement) {
		context = data.domElement;
	}
	else if (!!data && !!data.element) {
		context = data.element;
	}
	else {
		context = document;
	}

	stageModulesOnPage = dom.getElementsArray('.sc-md-details-stage-uc', context);

	for (i = 0; i < stageModulesOnPage.length; i++) {
		stageId = stageModulesOnPage[i].getAttribute('id');
		newStage = new __.UCStage(stageModulesOnPage[i]);
		newStage.id = stageId;
		newStage.delegate = dom.getEventDelegate('#' + newStage.id);
		newStage.selectorPrefix = '.' + newStage.id + ' ';
		newStage.addStageEvents();
		newStage.resetStage();
		newStage.loadImages();
	}
};

__.addEvents = function () {
	__.globalEventBus.on(CoreEvents.PAGE_READY, __.initStageModules);
	__.globalEventBus.on(CoreEvents.LAYER_LOADED, __.initStageModules);
};
__.updateSelectionWrapper = function (context, event, instance) {
	return (function () {
		instance.handleUpdateSelection(context, event);
	}());
};
__.arcClickWrapper = function (context, event, instance) {
	return (function () {
		instance.handleArcClick(context, event);
	}());
};
__.dotClickWrapper = function (context, event, instance) {
	return (function () {
		instance.handleDotClick(context, event);
	}());
};

__.UCStage = function (stageElement) {
	this.context = stageElement;
};

__.UCStage.prototype.addStageEvents = function () {
	var stageInstance = this, context;
	var selectorPrefix = this.selectorPrefix;
	__.domDelegate.on('click', selectorPrefix + __.oDefaults.sSelectorStageNaviPrev, function () {
		context = this;
		event.stopPropagation();
		return __.arcClickWrapper(context, event, stageInstance);
	});
	__.domDelegate.on('click', selectorPrefix + __.oDefaults.sSelectorStageNaviNext, function () {
		context = this;
		event.stopPropagation();
		return __.arcClickWrapper(context, event, stageInstance);
	});
	__.domDelegate.on('change', selectorPrefix + '.stage-uc-toggle', function () {
		context = this;
		return __.updateSelectionWrapper(context, event, stageInstance);
	});
	__.domDelegate.on('click', selectorPrefix + '.stage-uc-toggle-label', function () {
		context = this;
		event.stopPropagation();
		return __.dotClickWrapper(context, event, stageInstance);
	});
	__.domDelegate.on('mousedown', selectorPrefix + __.oDefaults.sSelectorStageImageWrapper, __.activateSwipe);
	__.domDelegate.on('touchstart', selectorPrefix + __.oDefaults.sSelectorStageImageWrapper, __.activateSwipe);
	__.domDelegate.on('mouseup', __.deactivateSwipe);
	__.domDelegate.on('touchend', __.deactivateSwipe);
	this.swipeActive = false;
};
__.UCStage.prototype.resetStage = function () {
	this.prev = 1;
};
/**
 * load images
 * @returns {void}
 */
__.UCStage.prototype.loadImages = function () {
	var i, imageSourceCount, imageSource, imageId, image,
		imageSources = dom.getElementsArray(__.oDefaults.sSelectorImageSource, this.context);

	for (i = 0, imageSourceCount = imageSources.length; i < imageSourceCount; i++) {
		imageSource = imageSources[i].getAttribute('data-src');
		imageId = imageSources[i].getAttribute('data-id');
		image = dom.getElement(__.oDefaults.selectorStageImageBegin + imageId + __.oDefaults.selectorStageImageEnd, this.context);
		image.src = imageSource;
	}
	__.setNeighborImagesToCurrent(1, this.context, this.selectorPrefix);
};
/**
 * handler for selection update
 * @param {UCStage} eventContext - stage instance
 * @returns {void}
 */
__.UCStage.prototype.handleUpdateSelection = function (eventContext) {
	var i, context, prev, selectorPrefix,
		imageWrappersCount,
		imageWrappers = dom.getElementsArray(this.selectorPrefix + __.oDefaults.sSelectorStageImageWrapper);
	for (i = 0, imageWrappersCount = imageWrappers.length; i < imageWrappersCount; i++) {
		imageWrappers[i].classList.remove(__.oDefaults.sClassAnimR2l);
		imageWrappers[i].classList.remove(__.oDefaults.sClassAnimL2r);
	}

	if (this.arcNavi) {
		this.handleArcNaviTransition(eventContext.value);
	}
	else {
		this.handleDotNaviTransition(eventContext.value);
	}

	if (eventContext.value !== this.prev) {
		this.prev = parseInt(eventContext.value, __.oDefaults.iDefaultRadix);
		prev = this.prev;
		context = this.context;
		selectorPrefix = this.selectorPrefix;
		setTimeout(function () {
			__.setNeighborImagesToCurrent(prev, context, selectorPrefix);
		}, 500);
	}
};
__.UCStage.prototype.handleArcClick = function (eventContext, event) {
	event.stopPropagation();
	this.arcNavi = true;
};
__.UCStage.prototype.handleDotClick = function (eventContext, event) {
	event.stopPropagation();
	this.arcNavi = false;
};
/**
 * perform transition for arc navi click
 * @param {int} index - the index of the clicked item
 * @returns {void}
 */
__.UCStage.prototype.handleArcNaviTransition = function (index) {
	var imageWrapperSelector = this.selectorPrefix + __.oDefaults.sSelectorStageItem + '-' + index + ' ' + __.oDefaults.sSelectorStageImageWrapper;

	if ((this.prev > index && Math.abs(index - this.prev) === 1) || parseInt(index, __.oDefaults.iDefaultRadix) !== 1 && Math.abs(index - this.prev) !== 1) {
		dom.getElement(imageWrapperSelector, this.context).classList.add(__.oDefaults.sClassAnimL2r);
	}
	else {
		dom.getElement(imageWrapperSelector, this.context).classList.add(__.oDefaults.sClassAnimR2l);
	}
};

/**
 * perform transition for dot navi click
 * @param {int} index - the index of the clicked item
 * @returns {void}
 */
__.UCStage.prototype.handleDotNaviTransition = function (index) {
	var imageWrapperSelector = this.selectorPrefix + __.oDefaults.sSelectorStageItem + '-' + index + ' ' + __.oDefaults.sSelectorStageImageWrapper;

	if (this.prev < index) {
		dom.getElement(imageWrapperSelector).classList.add(__.oDefaults.sClassAnimR2l);
	}
	else {
		dom.getElement(imageWrapperSelector).classList.add(__.oDefaults.sClassAnimL2r);
	}
};

__.activateSwipe = function (e) {
	if (e.type === 'touchstart') {
		__.swipeX = e.originalEvent.pageX;
	}
	else {
		__.swipeX = e.pageX;
	}
	__.domDelegate.on('mousemove', __.swipe);
	__.domDelegate.on('touchmove', __.swipe);
};

__.deactivateSwipe = function () {
	__.domDelegate.off('mousemove', __.swipe);
	__.domDelegate.off('touchmove', __.swipe);
};

__.swipe = function (e) {
	var pos;
	if (e.type === 'touchmove') {
		pos = e.originalEvent.pageX;
	}
	else {
		pos = e.pageX;
	}
	if (pos >= __.swipeX + 5) {
		__.deactivateSwipe(e);
		dom.getElement(__.oDefaults.sSelectorStageNaviPrev, dom.closest(e.target, '.stage-uc-item')).click();
	}
	else if (pos <= __.swipeX - 5) {
		__.deactivateSwipe(e);
		dom.getElement(__.oDefaults.sSelectorStageNaviNext, dom.closest(e.target, '.stage-uc-item')).click();
	}
};

/**
 * set sources of neighbor images to current image
 * @param {int} currentIndex - index of current image
 * @param {HTMLElement} context - HTML Element
 * @param {string} selectorPrefix - css selector
 * @returns {void}
 */
__.setNeighborImagesToCurrent = function (currentIndex, context, selectorPrefix) {
	var i, imageCount,
		leftImages = dom.getElementsArray(selectorPrefix + '.stage-uc-image.left img', context),
		rightImages = dom.getElementsArray(selectorPrefix + '.stage-uc-image.right img', context),
		neighbourImages = leftImages.concat(rightImages),
		imageSource = dom.getElement(selectorPrefix + __.oDefaults.sSelectorImageSource + '-' + currentIndex).getAttribute('data-src', context);

	for (i = 0, imageCount = neighbourImages.length; i < imageCount; i++) {
		neighbourImages[i].setAttribute('src', imageSource);
	}
};

// initialize module
__.addEvents();
__.initStageModules();

export {exports as scDetailsStageUc};
