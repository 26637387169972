import {STCK_STORE, ACTIONS, SELECTORS} from '@oneaudi/stck-store';
import {globalEventEmitter} from '../../stockcars-bundle';
import {scPage} from '../../stockcars-utils-bundle';
import {dom} from 'core-utils';

export class AozProductElement extends HTMLElement {
	constructor() {
		super();
	}

	static get defaults() {
		return {
			selectorInquiryButton: '.sc-aoz-product-add',
			selectorProductTitle: '.sc-aoz-product-text-title',
			selectorProductDescription: '.sc-aoz-product-text-description'
		};
	}

	connectedCallback() {
		this._initializeClassVariables();
		this._addEvents();
		this._addEllipsis(this._productTitleElement);
		this._addEllipsis(this._productDescriptionElement);
		this._setActiveState();
	}

	disconnectedCallback() {
		this._removeEvents();
	}

	_initializeClassVariables() {
		this._vehicleId = scPage.getVehicleIdFromUrl();
		this._productEan = this.dataset.eanCode;
		this._productName = this.dataset.productName;
		this._productPrice = this.dataset.productPrice;
		this._productDescription = this.dataset.productDescription;
		this._inquiryButton = this.querySelector(AozProductElement.defaults.selectorInquiryButton);
		this._productTitleElement = this.querySelector(AozProductElement.defaults.selectorProductTitle);
		this._productDescriptionElement = this.querySelector(AozProductElement.defaults.selectorProductDescription);
		this._debounceResizeHandler = dom.debounce(this._resizeHandler, 100).bind(this);
	}

	_addEvents() {
		this.unsubscribeAozState = STCK_STORE.observeStateFromStore(SELECTORS.AOZ.getAozStateRaw, this._setActiveState.bind(this));
		this._inquiryButton.addEventListener('click', this._inquiryButtonClickHandler.bind(this));
		window.addEventListener('resize', this._debounceResizeHandler);
	}

	_removeEvents() {
		this.unsubscribeAozState();
		this._inquiryButton.removeEventListener('click', this._inquiryButtonClickHandler);
		window.removeEventListener('resize', this._debounceResizeHandler);
	}

	_setActiveState() {
		const vehicleAozState = SELECTORS.AOZ.getAddedProductsByVehicleId(STCK_STORE.state, this._vehicleId);
		if (vehicleAozState && vehicleAozState.find(product => product.ean === this._productEan)) {
			this._inquiryButton.classList.add('inactive');
		}
		else {
			this._inquiryButton.classList.remove('inactive');
		}
	}

	/**
	 * resizeHandler
	 * @returns {void} void
	 */
	_resizeHandler() {
		this._addEllipsis(this._productTitleElement);
		this._addEllipsis(this._productDescriptionElement);
	}

	_addEllipsis(_element) {
		if (_element) {
			const originalText = _element.dataset.originalText;
			const textArray = originalText.split(/(?=[ -])/);
			_element.innerHTML = originalText;
			while ((_element.scrollHeight > _element.offsetHeight) && (textArray.length > 1)) {
				textArray.pop();
				_element.innerHTML = textArray.join('') + '...';
			}
		}
	}

	_inquiryButtonClickHandler(event) {
		event.preventDefault();
		const state = SELECTORS.AOZ.getAddedProductsByVehicleId(STCK_STORE.state, this._vehicleId);

		if (state && state.find(product => product.ean === this._productEan)) {
			this._removeProductFromState();
		}
		else {
			this._addProductToState();
			globalEventEmitter.emit('modalLayer:open', {
				layerType: 'AozProductAddedModalLayerElement',
				type: 'confirm',
				message: window.i18n['sc.aoz.added.message']
			});
		}
	}

	_addProductToState() {
		STCK_STORE.dispatch(ACTIONS.AOZ.addAozProductIdForVehicle({
			vehicleId: this._vehicleId,
			product: {
				ean: this._productEan,
				name: this._productName,
				description: this._productDescription,
				price: this._productPrice
			}
		}));
		this._inquiryButton.classList.add('inactive');
	}

	_removeProductFromState() {
		STCK_STORE.dispatch(ACTIONS.AOZ.removeAozProductIdFromVehicle({
			vehicleId: this._vehicleId,
			productEan: this._productEan
		}));
		this._inquiryButton.classList.remove('inactive');
	}
}

if (window.customElements.get('aoz-product-element') === undefined) {
	window.customElements.define('aoz-product-element', AozProductElement);
}
