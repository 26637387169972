import {dom} from 'core-utils';
import {jsLoader} from 'core-utils';
import {googleController} from '../stockcars-bundle';

export default class ScDealerInfoMapElement extends HTMLElement {

	constructor() {
		super();
	}


	static get defaults() {
		return {
			mapDefaultOptions: {
				mapTypeId: window.google ? google.maps.MapTypeId.ROADMAP : undefined,
				zoom: 16,
				disableDefaultUI: true,
				zoomControl: false,
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				scrollwheel: false
			}
		};
	}

	connectedCallback() {
		this._checkMapContainerAndScript();
	}

	disconnectedCallback() {
		window.removeEventListener('resize', this._centerFunction);
	}

	/**
	 * sets map options data
	 *
	 * @method getMapData
	 * @param {HTMLElement} mapContainer_ - container element
	 * @return {Object} options object
	 */
	_getMapData() {
		const mapOptions = {};
		mapOptions.longitude = this.getAttribute('data-long');
		mapOptions.latitude = this.getAttribute('data-lat');
		mapOptions.name = this.getAttribute('data-name');
		mapOptions.zoom = this.getAttribute('data-zoom') || '';
		mapOptions.hidemarker = this.getAttribute('data-hidemarker') && this.getAttribute('data-hidemarker').toLowerCase() === 'true' || false;
		return mapOptions;
	}

	/**
	 * renders the map via lat and long
	 *
	 * @method paintMap
	 * @param {Object} mapOptions_ - map options
	 * @return {void} returns google maps object
	 */
	_paintMap(mapOptions_) {
		const options = ScDealerInfoMapElement.defaults.mapDefaultOptions;
		options.zoom = mapOptions_.zoom.length ? parseInt(mapOptions_.zoom, 10) : options.zoom;
		options.center = googleController.getLatLng(mapOptions_.latitude, mapOptions_.longitude);
		return new google.maps.Map(this, options);
	}

	/**
	 * sets the marker via lat and long
	 *
	 * @method paintMarker
	 * @param {Object} map_ - google map
	 * @param {Object} mapOptions_ - map options
	 * @return {void} returns nothing
	 */
	_paintMarker(map_, mapOptions_) {
		if (!mapOptions_.hidemarker) {
			const markerSvg = [
				'<svg viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg">',
				'<g transform="translate(9.000000, 3.000000)" >',
				'<path d="M29,14.5 C29,17.2307346 27.3756039,21.4032521 24.5014468,26.6805337 C23.7204242,28.1145807 22.858069,29.6080526 21.927847,31.1462411 C20.3915741,33.686578 18.7462372,36.2262731 17.1009321,38.6452633 C16.5250671,39.4919219 15.9902581,40.2631342 15.5101605,40.943844 C15.3419945,41.1822793 15.1926348,41.3924315 15.0637887,41.5724171 C14.9862678,41.6807064 14.9329586,41.7546698 14.9047305,41.7935869 L14.5,42.3515367 L14.0952695,41.7935869 C14.0670414,41.7546698 14.0137322,41.6807064 13.9362113,41.5724171 C13.8073652,41.3924315 13.6580055,41.1822793 13.4898395,40.943844 C13.0097419,40.2631342 12.4749329,39.4919219 11.8990679,38.6452633 C10.2537628,36.2262731 8.60842589,33.686578 7.07215304,31.1462411 C6.14193103,29.6080526 5.27957581,28.1145807 4.49855321,26.6805337 C1.62439613,21.4032521 0,17.2307346 0,14.5 C0,6.52385763 6.52385763,0 14.5,0 C22.4761424,0 29,6.52385763 29,14.5 Z" fill="#000000"></path>',
				'<g transform="translate(4.250304, 10.664518)" stroke-linecap="round" stroke="#FFFFFF">',
				'<path d="M6.55600263,3.27800132 C6.55600263,5.08826197 5.08826197,6.55600263 3.27800132,6.55600263 C1.46774066,6.55600263 0,5.08826197 0,3.27800132 C0,1.46774066 1.46774066,0 3.27800132,0 C5.08826197,0 6.55600263,1.46774066 6.55600263,3.27800132 L6.55600263,3.27800132 Z"></path>',
				'<path d="M11.2115146,3.27800132 C11.2115146,5.08826197 9.74377399,6.55600263 7.93351333,6.55600263 C6.12325267,6.55600263 4.65551202,5.08826197 4.65551202,3.27800132 C4.65551202,1.46774066 6.12325267,0 7.93351333,0 C9.74377399,0 11.2115146,1.46774066 11.2115146,3.27800132 L11.2115146,3.27800132 Z"></path>',
				'<path d="M12.5890961,6.55600263 C10.7788355,6.55600263 9.3110948,5.08826197 9.3110948,3.27800132 C9.3110948,1.46774066 10.7788355,0 12.5890961,0 C14.3993568,0 15.8670974,1.46774066 15.8670974,3.27800132 C15.8670974,5.08826197 14.3993568,6.55600263 12.5890961,6.55600263 Z"></path>',
				'<path d="M20.5226094,3.27800132 C20.5226094,5.08826197 19.0548688,6.55600263 17.2446081,6.55600263 C15.4343475,6.55600263 13.9666068,5.08826197 13.9666068,3.27800132 C13.9666068,1.46774066 15.4343475,0 17.2446081,0 C19.0548688,0 20.5226094,1.46774066 20.5226094,3.27800132 L20.5226094,3.27800132 Z"></path>',
				'</g>',
				'</g>',
				'</svg>'
			].join('\n');

			const marker = new google.maps.Marker({ // eslint-disable-line no-unused-vars
				position: googleController.getLatLng(mapOptions_.latitude, mapOptions_.longitude),
				map: map_,
				icon: {
					url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(markerSvg),
					scaledSize: new google.maps.Size(48, 48)
				},
				title: mapOptions_.name
			});
		}
	}

	/**
	 * center the map via lat and long
	 *
	 * @method centerMap
	 * @param {Object} map_ - google map
	 * @param {Object} mapOptions_ - map options
	 * @return {void} returns nothing
	 */
	_centerMap(map_, mapOptions_) {
		const center = googleController.getLatLng(mapOptions_.latitude, mapOptions_.longitude);
		setTimeout(() => {
			map_.setCenter(center);
		}, 200);
	}

	/**
	 * check if map container is in DOM and if google maps script is already loaded
	 * loads the script via jsloader if not
	 *
	 * @method checkMapContainerAndScript
	 * @return {void} returns nothing
	 */
	_checkMapContainerAndScript() {
		if (window.google) {
			this._initMap();
		}
		else {
			const mapURL = this.getAttribute('data-url');
			jsLoader.loadURL(mapURL).then(() => {
				this._initMap();
			});
		}
	}

	/**
	 * initialize all maps, by getting map data from each container
	 * renders all maps + markers and adds eventListener for window-resize
	 *
	 * @method initMap
	 * @return {void} returns nothing
	 */
	_initMap() {
		if (!this.hasAttribute('data-map-initialized')) {
			const mapOptions = this._getMapData(this);
			const map = this._paintMap(mapOptions);
			this._centerFunction = (() => this._centerMap(map, mapOptions));
			this._centerFunction = dom.throttle(this._centerFunction, 25).bind(this);
			this._paintMarker(map, mapOptions);
			window.addEventListener('resize', this._centerFunction);
			// set init status in DOM to prevent multiple initialization
			this.setAttribute('data-map-initialized', 'true');
		}
	}
}

if (window.customElements.get('sc-dealerinfo-map-element') === undefined) {
	window.customElements.define('sc-dealerinfo-map-element', ScDealerInfoMapElement);
}

