(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("core-utils"), require("stockcars-utils-bundle"), require("stockcars-bundle"), require("core-application"), require("core-global"), require("microkernel"), require("stockcars-microkernel-store-bundle"));
	else if(typeof define === 'function' && define.amd)
		define("stockcars-detail-bundle", ["core-utils", "stockcars-utils-bundle", "stockcars-bundle", "core-application", "core-global", "microkernel", "stockcars-microkernel-store-bundle"], factory);
	else if(typeof exports === 'object')
		exports["stockcars-detail-bundle"] = factory(require("core-utils"), require("stockcars-utils-bundle"), require("stockcars-bundle"), require("core-application"), require("core-global"), require("microkernel"), require("stockcars-microkernel-store-bundle"));
	else
		root["stockcars-detail-bundle"] = factory(root["core-utils"], root["stockcars-utils-bundle"], root["stockcars-bundle"], root["core-application"], root["core-global"], root["microkernel"], root["stockcars-microkernel-store-bundle"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__10__, __WEBPACK_EXTERNAL_MODULE__20__, __WEBPACK_EXTERNAL_MODULE__54__) {
return 