import {device as DEVICE} from 'core-utils';
import {dom} from 'core-utils';
import {SELECTORS, ACTIONS, STCK_STORE} from '@oneaudi/stck-store';

export default class ScDetailAnchorNavigationElement extends HTMLElement {
	constructor() {
		super();
	}

	static get defaults() {
		return {
			'navWrapper': '.sc-navigation-wrap',
			'scrollToTopArrow': '.sc-md-anchor-navigation .sc-navigation-wrap.sc-sticky-progress .sc-icon-collapse',
			'stickyProgress': 'sc-sticky-progress',
			'detailPaging': '.sc-j-detail-paging-wrapper'
		};
	}

	connectedCallback() {
		this._subscribeToUIModelChanges();
		this._bindFunctions();
		this._checkDetailPaging();
		this._addEventListener();
		this._showWLTPonMobile();
		this._hideItemLabel();
		this._getIdToHide();
	}

	disconnectedCallback() {
		this._removeEventListener();
		this._resetAnchorNavigationItemsInStore();
		this._unsubscribeStoreCallBack();
	}

	/**
	 * _checkDetailPaging
	 * @returns {void} nothing
	 */
	_checkDetailPaging() {
		const detailPagingElement = document.querySelector(ScDetailAnchorNavigationElement.defaults.detailPaging);
		const navBar = this.querySelector(ScDetailAnchorNavigationElement.defaults.navWrapper);
		if (detailPagingElement) {
			const detailPagingElementPosition = detailPagingElement.getBoundingClientRect().top;
			this.topPosition = detailPagingElementPosition < this.getBoundingClientRect().top ? 60 : 0;
			navBar.style.top = `${this.topPosition}px`;
		}
		else {
			this.topPosition = 0;
			navBar.style.top = 0;
		}
	}

	/**
	 * _getIdToHide
	 * @returns {void} nothing
	 */

	_getIdToHide() {
		const navListLinks = this.querySelectorAll('.tab-amount-default li a');
		navListLinks.forEach(link => {
			const id = link.getAttribute('href').replace('#', '');
			const lastLabel = document.getElementById(id);
			if (lastLabel.parentElement.nextElementSibling && lastLabel.parentElement.nextElementSibling.nodeName === 'INPUT') {
				STCK_STORE.dispatch(ACTIONS.UI.setAnchorNavigationHideItemOnDetailPage(id));
			}
		});
	}

	/**
	 * subscribeToUIModelChanges
	 * @returns {void} nothing
	 */
	_subscribeToUIModelChanges() {
		this._unsubscribeStoreCallBack = STCK_STORE.observeStateFromStore(SELECTORS.UI.getAnchorNavigationHideItemOnDetailPage, this._hideItemLabel.bind(this));
	}

	/**
	 * _resetAnchorNavigationItemsInSore
	 * @returns {void} void
	 */
	_resetAnchorNavigationItemsInStore() {
		STCK_STORE.dispatch(ACTIONS.UI.resetAnchorNavigationHideItemOnDetailPage());
	}

	/**
	 * Uses the id on the store to hide item labels
	 * @returns {void} void
	 */
	_hideItemLabel() {
		const ids = SELECTORS.UI.getAnchorNavigationHideItemOnDetailPage(STCK_STORE.state);
		ids.forEach(id => {
			const itemLabelDesktopViewport = this.querySelector(`a[href="#${id}"]`);
			const pageContext = this.closest(".nm-content");
			const itemLabelMobileViewport = pageContext.querySelector(`[id^="${id}"]`);
			if (itemLabelDesktopViewport && itemLabelMobileViewport) {
				itemLabelDesktopViewport.parentElement.remove();
				itemLabelMobileViewport.parentElement.remove();
			}
		});
	}

	/**
	 * handles click on desktop anchor navigation item
	 * @method handleDesktopNavLinkClick
	 * @param {Event} event - click event
	 * @return {void} returns nothing
	 */
	_handleDesktopNavLinkClick(event) {
		const target = dom.closest(event.target, '[href]');
		const targetId = target ? target.getAttribute('href') : '';
		const offset = dom.getOffset('div' + targetId);
		const offsetTop = offset.top;
		const navBar = this.querySelector(ScDetailAnchorNavigationElement.defaults.navWrapper);
		let dest = 0;
		event.preventDefault();
		if (dom.isElement(navBar) && offset) {
			const navBarHeight = navBar.clientHeight || 0;
			if (offsetTop > navBarHeight) {
				if (!navBar.classList.contains(ScDetailAnchorNavigationElement.defaults.stickyProgress)) {
					dest = offsetTop - navBarHeight - (navBarHeight / 3);
				}
				else {
					dest = offsetTop - navBarHeight;
				}
			}
			else {
				dest = navBarHeight + navBar.offsetTop;
			}
			dom.scrollTo(parseInt(dest, 10), 500);
		}
	}

	/**
	 * handles click on mobile anchor navigation item
	 * @method handleMobileNavLinkClick
	 * @param {Event} event - click event
	 * @return {void} returns nothing
	 */
	_handleMobileNavLinkClick(event) {
		const navBar = this.querySelector(ScDetailAnchorNavigationElement.defaults.navWrapper);
		const navLabel = event.target.closest('label');
		if (dom.isElement(navBar) && navLabel) {
			const navLabelId = navLabel ? navLabel.getAttribute('for') : '.sc-md-anchor-navigation';
			const offsetTop = dom.getOffset('div' + navLabelId).top;
			window.scrollTo(0, offsetTop);
		}
	}

	/**
	 * handles click on scroll to top arrow
	 * @method handleScrollToTop
	 * @return {void} returns nothing
	 */
	_handleScrollToTop() {
		dom.scrollTo(0, 500);
	}

	/**
	 * handles viewport scroll
	 * @method handleViewportScroll
	 * @returns {void} nothing
	 */
	_handleViewportScroll() {
		if (!DEVICE.isMobile()) {
			const navBar = this.querySelector(ScDetailAnchorNavigationElement.defaults.navWrapper);
			if (this.getBoundingClientRect().top > this.topPosition) {
				navBar.classList.remove(ScDetailAnchorNavigationElement.defaults.stickyProgress);
			}
			else {
				navBar.classList.add(ScDetailAnchorNavigationElement.defaults.stickyProgress);
			}
		}
	}

	/**
	 * add all Event Listener
	 *
	 * @method addEventListener
	 * @return {void} returns nothing
	 */
	_addEventListener() {
		window.addEventListener('scroll', this._handleViewportScroll);
		const domDelegate = dom.getEventDelegate('body');
		domDelegate.on('click', '.sc-navigation a', this._handleDesktopNavLinkClick);
		domDelegate.on('click', '.sc-visibility-switch-label.sc-mobile-label', this._handleMobileNavLinkClick);
		domDelegate.on('click', ScDetailAnchorNavigationElement.defaults.scrollToTopArrow, this._handleScrollToTop);
	}

	_bindFunctions() {
		this._handleDesktopNavLinkClick = this._handleDesktopNavLinkClick.bind(this);
		this._handleMobileNavLinkClick = this._handleMobileNavLinkClick.bind(this);
		this._handleScrollToTop = this._handleScrollToTop.bind(this);
		this._handleViewportScroll = dom.throttle(this._handleViewportScroll.bind(this), 100).bind(this);
	}

	/**
	 * remove all Event Listener
	 *
	 * @method removeEventListener
	 * @return {void} returns nothing
	 */
	_removeEventListener() {
		window.removeEventListener('scroll', this._handlerScroll);
		const domDelegate = dom.getEventDelegate('body');
		domDelegate.on('click', '.sc-navigation a', this._handleDesktopNavLinkClick);
		domDelegate.off('click', '.sc-visibility-switch-label.sc-mobile-label', this._handleMobileNavLinkClick);
		domDelegate.off('click', ScDetailAnchorNavigationElement.defaults.scrollToTopArrow, this._handleScrollToTop);
	}

	/**
	 * initially show/open Emission Element on mobile devices
	 * for german market only (legal requirement)
	 * @returns {void} nothing
	 */
	_showWLTPonMobile() { // eslint-disable-line max-statements
		if (SETUPS.get("nemo.locale.country") === "DE" && DEVICE.isMobile()) {
			const techDataTab = document.querySelector("[data-module='details-consumption-emission']") || document.querySelector("[data-module='details-technical-data']");
			if (techDataTab) {
				const hasModeTechData = document.querySelector("input.sc-show-more-techdata");
				if (hasModeTechData) {
					hasModeTechData.setAttribute("checked", "checked");
				}
				// deactivate currently active
				const previouslySelected = document.querySelector("input[name='sc-anchor-nav']:checked");
				if (previouslySelected) {
					previouslySelected.removeAttribute("checked");
				}
				let techdataItem = techDataTab.previousElementSibling;
				if (techdataItem) {
					const navId = dom.getElement(".sc-anchor-navigation", techdataItem).getAttribute("id");
					const inputField = document.getElementById("#" + navId);
					if (inputField) {
						inputField.setAttribute("checked", "checked");
					}
				}
			}
		}

	}

}
if (window.customElements.get('sc-anchor-navigation-element') === undefined) {
	window.customElements.define('sc-anchor-navigation-element', ScDetailAnchorNavigationElement);
}
