import {template} from 'core-utils';
import {modalLayer, ModalLayerContentBase} from 'core-application';
import {gridStageLayerTemplate} from './grid-stage-layer-template';

export default class ModalGridStageElement extends ModalLayerContentBase {
	constructor(data_ = {}) {
		super();
		this._bindContext();
		this._data = data_;
	}

	/**
	 * static getter function for default CSS Selectors
	 * @static
	 * @returns {object <{closeButton: string, confirmButton: string}>} defaults
	 */
	static get defaults() {
		return {
			closeButton: '.sc-grid-stage-layer-close'
		};
	}

	/**
	 * static getter for type
	 * @static
	 * @returns {string} type
	 */
	static get type() {
		return 'ModalGridStageElement';
	}

	/**
	 * getContent
	 * @static
	 * @param {object} data_ data-attributes object
	 * @returns {Promise<ModalGridStageElement>} ModalGridStageElement instance
	 */
	static async getContent(data_) {
		return new ModalGridStageElement(data_);
	}

	/**
	 * connectedCallback
	 * @returns {void} returns nothing
	 */
	connectedCallback() {
		this._render();
		this._initializeProperties();
		this._addEvents();
	}

	/**
	 * disconnectedCallback
	 * @returns {void} returns nothing
	 */
	disconnectedCallback() {
		this._removeEvents();
	}

	/**
	 * _generateTemplateContentData
	 * @param {object} payload data attributes object
	 * @returns {{urls: Array, index: number}} template data
	 * @private
	 */
	static _generateTemplateContentData(payload = {}) {
		const urls = payload.urls ? JSON.parse(payload.urls) : [];
		const index = payload.index ? parseInt(payload.index, 10) : 0;
		const hasCarImages = (payload.hasCarImages && payload.hasCarImages.toLowerCase() === 'true') || false;
		const {threeSixtyUrl = '', showThreesixty = false} = payload;
		const marketType = payload.marketType;
		return {urls, index, hasCarImages, threeSixtyUrl, showThreesixty, marketType};
	}

	/**
	 * _initializeProperties
	 * @private
	 * @returns {void} void
	 */
	_initializeProperties() {
		this._closeButton = this.querySelector(ModalGridStageElement.defaults.closeButton);
	}

	/**
	 * render
	 * @returns {void} void
	 */
	_render() {
		const data = ModalGridStageElement._generateTemplateContentData(this._data);
		this.innerHTML = template.render(gridStageLayerTemplate, data);
	}

	/**
	 * _bindContext - bind 'this' context to necessary functions
	 * @returns {void} returns nothing
	 */
	_bindContext() {
		this._closeButtonClickHandler = this._closeButtonClickHandler.bind(this);
		this._resizeHandler = this._resizeHandler.bind(this);
	}

	/**
	 * _addEvents
	 * @returns {void} returns nothing
	 */
	_addEvents() {
		this._closeButton.addEventListener('click', this._closeButtonClickHandler);
		window.addEventListener('resize', this._resizeHandler);
	}

	/**
	 * _removeEvents
	 * @returns {void} returns nothing
	 */
	_removeEvents() {
		this._closeButton.removeEventListener('click', this._closeButtonClickHandler);
		window.removeEventListener('resize', this._resizeHandler);
	}

	/**
	 * _resizeHandler
	 * @private
	 * @returns {void} void
	 */
	_resizeHandler() {
		const viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
		if (viewportWidth < 480) {
			this._triggerClose(true);
		}
	}

	/**
	 * _closeButtonClickHandler
	 * @private
	 * @returns {void} void
	 */
	_closeButtonClickHandler() {
		this._triggerClose();
	}
}

modalLayer.registerLayerType(ModalGridStageElement);

if (window.customElements.get('modal-grid-stage-element') === undefined) {
	window.customElements.define('modal-grid-stage-element', ModalGridStageElement);
}
