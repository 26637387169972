import {contactButtonTemplate} from '../../stockcars-bundle';
import {phoneButtonTemplate} from '../../stockcars-bundle';

const exports = `
	<div class="sc-dealerinfo-buttons">
		<div class="sc-details-dealerinfo-buttons-desktop">
			{{~it.desktop :item}}
				{{? item.type ==="contact" || item.type === "aoz" || item.type ==="nws"}}
					${contactButtonTemplate}
				{{?? item.type === "phone"}}
					${phoneButtonTemplate}
				{{?}}
			{{~}}
		</div>
		<div class="sc-details-dealerinfo-buttons-mobile">
			{{~it.mobile :item}}
				{{? item.type === "contact" || item.type ==="aoz" || item.type ==="nws"}}
					${contactButtonTemplate}
				{{?? item.type === "phone"}}
					${phoneButtonTemplate}
				{{?}}
			{{~}}	
		</div>
	</div>
`;

export {exports as detailsDealerinfoButtonsTemplate};
