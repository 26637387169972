import {dom} from 'core-utils';
import {globalEventEmitter, scsController} from '../../stockcars-bundle';
import {FORMAT_UTILS, intersectionObserver, IntersectionObserverClass, scPage} from '../../stockcars-utils-bundle';
import {STCK_STORE, SELECTORS} from '@oneaudi/stck-store';

export default class PagingElement extends HTMLElement {
	constructor() {
		super();
		this.currentVehicleIndex = 0;
		this.isFavoriteVehiclesPaging = false;
		this._globalEventBus = globalEventEmitter;
		this.unsubscribeFavoritesStateObserver = null;
		this.unsubscribeResultsStateObserver = null;
	}

	static isFetching = false;

	// use as session storage key
	static previousPageKey = 'previousPage';

	/**
	 * defaults static getter
	 * @returns {{nextClick: string, prevClick: string, hideSearchLinkClass: string, previousPage: string, module: string, pagingContainer: string, pagingCount: string, pagingCurrent: string, backLink: string, classDetailContainer: string}} defaults
	 */
	static get defaults() {
		return {
			'nextClick': '.j-stck-detail-next',
			'prevClick': '.j-stck-detail-previous',
			'hideSearchLinkClass': 'j-stck-detail-paging-initial',
			'previousPage': 'j-stck-detail-paging-previous',
			'module': '.sc-md-detail-paging',
			'pagingContainer': '.sc-detail-paging-container',
			'pagingCount': '.sc-detail-paging-total',
			'pagingCurrent': '.sc-detail-paging-current',
			'backLink': '.sc-detail-paging-backlink',
			'classDetailContainer': '.sc-detail-module.sc-detail-carinfo',
			scrollChecker: '.sc-j-detail-paging-scroll-check',
			classFixed: 'sc-j-detail-paging-sticky',
			classStickyWrapper: '.sc-j-detail-paging-wrapper',
			classDetailsPagingPlaceholder: '.sc-j-detail-paging-placeholder-for-sticky'
		};
	}

	/**
	 * connected callback
	 * @returns {void} void
	 */
	connectedCallback() {
		// Feature Toggle to hide the Legacy Paging
		if (SETUPS.get('scopes.featureToggles.disablePaging')) {
			return;
		}
		this.preloadVehiclesIfNeeded = this.preloadVehiclesIfNeeded.bind(this);
		this.update = this.update.bind(this);
		this._setProperties();
		this.isFavoriteVehiclesPaging = location.href.indexOf('detail_fav') > -1;
		this._setPagingLinks();
		this._nextClickElement.addEventListener("click", this.preloadVehiclesIfNeeded);
		this.unsubscribeFavoritesStateObserver = STCK_STORE.observeStateFromStore(SELECTORS.VEHICLES.getFavoriteVehicleIdsState, this.update);
		this.unsubscribeResultsStateObserver = STCK_STORE.observeStateFromStore(SELECTORS.VEHICLES.getResultVehicleIdsState, this.update);
		this._addStickyFunctionality();
	}

	/**
	 * disconnected callback
	 * @returns {void} void
	 */
	disconnectedCallback() {
		this._nextClickElement.removeEventListener("click", this.preloadVehiclesIfNeeded);
		this.unsubscribeFavoritesStateObserver();
		this.unsubscribeResultsStateObserver();
	}

	/**
	 * scsModel callback function
	 * @returns {void} void
	 */
	update() {
		this._setPagingLinks();
	}

	/**
	 * fetch more vehicles if next pages vehicles hasve not yet been loaded
	 * @returns {void} nothing
	 */
	preloadVehiclesIfNeeded() {
		// increment results if not loaded yet
		const currentLoadedResults = SELECTORS.VEHICLES.getResultVehicleIdsState(STCK_STORE.state).length;
		if (!this.isFavoriteVehiclesPaging && this.currentVehicleIndex + 2 >= currentLoadedResults && currentLoadedResults < SELECTORS.VEHICLES.getTotalCountState(STCK_STORE.state)) {
			scsController.incrementVehicleResults();
		}
	}

	/**
	 * set private properties
	 * @private
	 * @returns {void} void
	 */
	_setProperties() {
		this._pagingContainer = dom.getElement(PagingElement.defaults.pagingContainer, this);
		this._vehicleId = this._pagingContainer.getAttribute('data-vehicle-id');
		this._detailsUrl = this._pagingContainer.getAttribute('data-details-url') || '';
		this._prevClickElement = dom.getElement(PagingElement.defaults.prevClick, this);
		this._nextClickElement = dom.getElement(PagingElement.defaults.nextClick, this);
		this._backLink = dom.getElement(PagingElement.defaults.backLink, this);
		this._moduleContainer = dom.getElement(PagingElement.defaults.module, this);
	}

	/**
	 * set paging links
	 *
	 * @method setPagingLinks
	 * @returns {void} returns nothing
	 */
	_setPagingLinks() {
		if (!scPage.isElementInOpenedLayerContext(this)) {
			PagingElement._setPreviousPage();
			const currentIndex = this._getCurrentVehicleIndex();
			if (currentIndex !== null && !isNaN(currentIndex) && currentIndex >= 0) {
				this._updatePrevNextBackLinks();
			}
		}
		this._setBackLink();
	}

	/**
	 * update Pagination Links
	 *
	 * @method updatePrevNextBackLinks
	 * @return {void} returns nothing
	 */
	_updatePrevNextBackLinks() {
		this._setPrevNextClick();
	}

	/**
	 * set back link
	 * @method setBackLink
	 * @return {void} returns nothing
	 */
	_setBackLink() {
		const previousPage = window.sessionStorage.getItem(PagingElement.previousPageKey);

		if (!!previousPage) {
			this._backLink.href = previousPage;
		}
		else {
			this._addBackLinkJsClass();
		}
	}

	/**
	 * set paging link on back and next buttons
	 * @method setPrevClick
	 * @return {void} returns nothing
	 */
	_setPrevNextClick() {
		if (this.isFavoriteVehiclesPaging) {
			// if favorite use vehicles from model favorite
			this._createPrevNextLink(SELECTORS.VEHICLES.getFavoriteVehiclesMap(STCK_STORE.state));
		}
		else {
			this._createPrevNextLink(SELECTORS.VEHICLES.getResultVehiclesMap(STCK_STORE.state));
		}
	}

	/**
	 * createPrevNextLink
	 *
	 * @method createPrevNextLink
	 * @param {Map} vehicles_ - vehiclesMap
	 * @return {void} returns nothing
	 */
	_createPrevNextLink(vehicles_ = new Map()) {
		let index = 0;
		vehicles_.forEach((vehicle) => {
			if (vehicle.id === this._vehicleId) {
				this.currentVehicleIndex = index;
			}
			index++;
		});
		this._setPrevClick(this.currentVehicleIndex, this._prevClickElement, vehicles_);
		this._setNextClick(this.currentVehicleIndex, vehicles_.size, this._nextClickElement, vehicles_);
		this._setCounts();
		this._removeContainersSearchLinkClass();
	}

	/**
	 * set paging count
	 * @method setCounts
	 * @return {void} returns nothing
	 */
	async _setCounts() {
		const currentCountElement = dom.getElement(PagingElement.defaults.pagingCurrent, this);
		currentCountElement.innerHTML = FORMAT_UTILS.formatCount(this.currentVehicleIndex + 1);

		const totalCountElement = dom.getElement(PagingElement.defaults.pagingCount, this);

		if (this.isFavoriteVehiclesPaging) {
			totalCountElement.innerHTML = FORMAT_UTILS.formatCount(SELECTORS.VEHICLES.getFavoriteVehicleIdsState(STCK_STORE.state).length);
		}
		else {
			const numLoadedVehicles = SELECTORS.VEHICLES.getResultVehiclesMap(STCK_STORE.state).size;
			const totalCount = SELECTORS.VEHICLES.getTotalCountState(STCK_STORE.state);
			if (this.currentVehicleIndex === numLoadedVehicles - 1 && this.currentVehicleIndex !== totalCount - 1) {
				if (PagingElement.isFetching === false) {
					PagingElement.isFetching = true;
					await scsController.incrementVehicleResults();
					PagingElement.isFetching = false;
				}
			}
			totalCountElement.innerHTML = FORMAT_UTILS.formatCount(totalCount);
		}
	}

	/**
	 * _removeContainersSearchLinkClass
	 * @private
	 * @returns {void} void
	 */
	_removeContainersSearchLinkClass() {
		this._moduleContainer.classList.remove(PagingElement.defaults.hideSearchLinkClass);
	}

	/**
	 * _setPrevClick
	 * @param {number} index current index
	 * @param {HTMLElement} previousButton previous vehicle button
	 * @param {Array} loadedVehicles array of loaded vehicles
	 * @private
	 * @returns {void} void
	 */
	_setPrevClick(index, previousButton, loadedVehicles) {
		if (index === 0) {
			previousButton.classList.add('nm-hidden');
		}
		else {
			previousButton.classList.remove('nm-hidden');
			this._setDetailsURL(previousButton, [...loadedVehicles.values()][index - 1].id);
		}
	}

	/**
	 * _setNextClick
	 * @param {number} index current index
	 * @param {number} totalCountOfLoadedVehicles total count of loaded vehicles
	 * @param {HTMLElement} nextButton next vehicle button
	 * @param {Array} loadedVehicles array of loaded vehicles
	 * @private
	 * @returns {void} void
	 */
	_setNextClick(index, totalCountOfLoadedVehicles, nextButton, loadedVehicles) {
		if (index === totalCountOfLoadedVehicles - 1 || totalCountOfLoadedVehicles === 0) {
			nextButton.classList.add('nm-hidden');
		}
		else {
			nextButton.classList.remove('nm-hidden');
			this._setDetailsURL(nextButton, [...loadedVehicles.values()][index + 1].id);
		}
	}

	/**
	 * sets detail url
	 * @method setDetailsURL
	 * @param {HTMLElement} pagingButton - link
	 * @param {string} vehicleId - id of the current vehicle
	 * @return {void} returns nothing
	 */
	_setDetailsURL(pagingButton, vehicleId = '') {
		if (pagingButton) {
			let url = this._detailsUrl.replace(/SC_VEHICLE_ID/i, vehicleId);
			if (this.isFavoriteVehiclesPaging) {
				url = url.replace(/sc_detail/, 'sc_detail_fav');
			}
			pagingButton.href = url;
		}
	}

	/**
	 * _addBackLinkJsClass
	 * @private
	 * @returns {void} void
	 */
	_addBackLinkJsClass() {
		this._moduleContainer.classList.remove(PagingElement.defaults.hideSearchLinkClass);
		this._moduleContainer.classList.add(PagingElement.defaults.previousPage);
	}

	/**
	 * _setPreviousPage static set previous page
	 * @private
	 * @returns {void} void
	 */
	static _setPreviousPage() {
		const previousPage = document.referrer;
		const hasPreviousPage = previousPage !== '';
		const stockcarStartpage = SETUPS.get('stockcar.url.startpage') || '';

		// assuming `stockcarStartpage` is always of this pattern: "/de/brand/de/neuwagenboerse.html"
		const isFromSameStockMarket = previousPage.includes(stockcarStartpage.split('.')[0]);

		const isDetailPage = previousPage.search(/sc_detail/i) > 0;

		if (hasPreviousPage && !isDetailPage && isFromSameStockMarket) {
			window.sessionStorage.setItem(PagingElement.previousPageKey, previousPage);
		}
	}

	/**
	 * returns actual Index of the chosen detail Vehicle
	 * @param {HTMLElement} element element
	 * @method getCurrentVehicleIndex
	 * @return {boolean | number} returns actual index
	 */
	_getCurrentVehicleIndex() {
		let index = -1;
		if (this.isFavoriteVehiclesPaging) {
			index = SELECTORS.VEHICLES.getFavoriteVehicleIdsState(STCK_STORE.state).indexOf(this._vehicleId);
		}
		else {
			index = SELECTORS.VEHICLES.getResultVehicleIdsState(STCK_STORE.state).indexOf(this._vehicleId);
		}
		return index;
	}

	/**
	 * _addStickyFunctionality
	 *
	 * @method _addStickyFunctionality
	 * @return {void} returns nothing
	 */
	_addStickyFunctionality() {
		this._getStickyElements();
		this._removeStickyState();
		this.stickyScrollMarker = this.parentElement.querySelector(PagingElement.defaults.scrollChecker);
		if (dom.isElement(this.stickyScrollMarker)) {
			this._registerIntersectionObserver();
		}
	}

	/**
	 * _getStickyElements
	 *
	 * @method _getStickyElements
	 * @return {void} returns nothing
	 */
	_getStickyElements() {
		this.detailsPagingStickyWrapper = dom.getElement(PagingElement.defaults.classStickyWrapper, this);
		this.stickyClassName = PagingElement.defaults.classFixed;
		this.detailsPagingPlaceholder = dom.getElement(PagingElement.defaults.classDetailsPagingPlaceholder, this);
	}

	/**
	 * _registerIntersectionObserver
	 *
	 * @method _registerIntersectionObserver
	 * @return {void} returns nothing
	 */
	_registerIntersectionObserver() {
		intersectionObserver.unregisterObserver(this.stickyScrollMarker);
		intersectionObserver.registerObserver(this.stickyScrollMarker, this._handleIntersectionForStickyPaging.bind(this), {
			threshold: [0.5]
		});
	}

	/**
	 * _handleIntersectionForStickyPaging sets and remove sticky state depending from intersection observers
	 * @param {Array<Object>} entries intersection observer entries
	 * @private
	 * @return {void} void
	 */
	_handleIntersectionForStickyPaging(entries) {
		if (!IntersectionObserverClass.isElementVisible(entries)) {
			if (entries[0] && entries[0].boundingClientRect.top <= 0) {
				this._addStickyState();
			}
		}
		else {
			if (entries[0] && entries[0].boundingClientRect.top >= 0) {
				this._removeStickyState();
			}
		}
	}

	/**
	 * _addStickyState adds the sticky state to the compare panel, sets placeholder space
	 * @private
	 * @returns {void} void
	 */
	_addStickyState() {
		this.detailsPagingStickyWrapper.classList.add(this.stickyClassName);
		this.detailsPagingPlaceholder.style.display = 'block';
		this.sticky = true;
	}

	/**
	 * _removeStickyState removes the sticky state and resets the space placeholder value
	 * @private
	 * @returns {void} void
	 */
	_removeStickyState() {
		this.detailsPagingStickyWrapper.classList.remove(this.stickyClassName);
		this.detailsPagingPlaceholder.style.display = 'none';
		this.sticky = false;
	}

}

if (window.customElements.get('details-paging-element') === undefined) {
	window.customElements.define('details-paging-element', PagingElement);
}
