import {dom} from 'core-utils';
import {CoreEvents} from '@audi/audi-core-events';
import {appEvents} from 'core-application';
import {globalEventEmitter} from '../../stockcars-bundle';
import {patternMatching} from 'core-global';
import {intersectionObserver, IntersectionObserverClass} from '../../stockcars-utils-bundle';

class PatchLayerLinksClass {
	constructor() {
		this.globalEventBus = globalEventEmitter;
	}

	/**
	 * defaults
	 * @return {{classLayerLink: string, classDetailCarInfo: string, classDetailContainer: string}} defaults
	 */
	static get defaults() {
		return {
			'classLayerLink': '.sc-j-layerLink',
			'classLayerLinkPatched': 'sc-j-layerLink-patched',
			'classDetailCarInfo': '.sc-detail-carinfo-buttons [data-vehicle-id]',
			'classDetailContainer': '.sc-detail-module'
		};
	}

	/**
	 * add all Event Listener
	 * @method addEventListener
	 * @return {void} returns nothing
	 */
	_addEventListener() {
		this.globalEventBus.on(CoreEvents.PAGE_READY, this._handleLayerEvent);
		document.addEventListener('LAYER_LOADED', this._handleLayerEvent);
		document.addEventListener(appEvents.CONTENT_RENDERED, this._handleContentRendered);
	}

	/**
	 * public initialize method
	 * @return {void} void
	 */
	initialize() {
		this._handleContentRendered = dom.debounce(this._handleLayerLink, 100).bind(this);
		this._handleLayerEvent = dom.debounce(this._handleLayerLink, 100).bind(this);
		this._getContext = this._getContext.bind(this);
		this._addEventListener();

		if (document.querySelector(PatchLayerLinksClass.defaults.classDetailContainer)) {
			this._handleLayerLink();
		}
	}

	/**
	 * @method getContext
	 * @param {event} event_ - Layer Event
	 * @returns {object} returns context
	 */
	_getContext(event_) {
		if (event_ && event_.detail && event_.detail.element && event_.detail.type && (event_.detail.type === 'modal_layer' || event_.detail.type === 'layer')) {
			return event_.detail.element;
		}
		return document;
	}

	/**
	 * @method handleLayerLink
	 * @param {event} event_ - Layer Event
	 * @returns {void} returns nothing
	 */
	_handleLayerLink(event_) {
		const context = this._getContext(event_);
		const layerLinks = dom.getElementsArray(PatchLayerLinksClass.defaults.classLayerLink, context);
		layerLinks.forEach(link => {
			intersectionObserver.registerObserver(link, this._handleIntersection.bind(this), {threshold: [0.5]});
		});
	}

	_handleIntersection(entries = []) {
		if (IntersectionObserverClass.isElementVisible(entries)) {
			entries.forEach(link => {
				const elem = link.target;
				if (!!elem.href) {
					if (!elem.classList.contains('sc-j-layerLink-patched')) {
						this._patchPatternUrl(elem).then((url) => this._addParamVehicleId(elem, url));
					}
					intersectionObserver.unregisterObserver(link);
				}
			});
		}
	}

	/**
	 * @method _addParamVehicleId
	 * @param {HTMLElement} link - anchor
	 * @param {URL} url - url object
	 * @returns {void} returns nothing
	 */
	_addParamVehicleId(link, url) {
		// patch only relative urls
		if (url.host === location.host) {
			// add vehicle id param only if does not already exist
			if (!!url && !!url.href && url.href.indexOf('vehicleid=') < 0) {
				let vehicleId = (!!link.closest('[data-vehicle-id]') && !!link.closest('[data-vehicle-id]').dataset && !!link.closest('[data-vehicle-id]').dataset.vehicleId) ? link.closest('[data-vehicle-id]').dataset.vehicleId : '';
				const separatingElement = !!url.search ? '&' : '?';
				// patch relative url with new vehicle id param
				link.href = `${url.pathname}${url.search}${separatingElement}vehicleid=${vehicleId}${url.hash}`;
				link.classList.add('sc-j-layerLink-patched');
			}
		}
		else {
			link.href = url;
		}
	}

	/**
	 * @method _patchPatternUrl
	 * @param {HTMLElement} link - anchor
	 * @returns {URL} returns url object
	 */
	async _patchPatternUrl(link) {
		let url = link.href || '';
		const pattern = link.dataset.pattern;
		if (!!link && !!pattern) {
			if (pattern.includes('{{')) {
				// remove attribute to prevent multiple event calls (content:rendered)
				link.removeAttribute('data-pattern');
				// replace pattern in url
				url = await patternMatching.getReplacedUrl(pattern, link.href);
			}
			else {
				url = pattern;
			}
		}
		return new URL(url);
	}
}

/**
 * PatchLayerLinksClass instance
 * @type {PatchLayerLinksClass}
 */
const patchLayerLinks = new PatchLayerLinksClass();
patchLayerLinks.initialize();

export {PatchLayerLinksClass, patchLayerLinks};
