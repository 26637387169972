const exports = `
	<div class="modal-layer__inner">
		<div class="modal-layer__header">
			<div class="modal-layer--close">
				<div class="modal-layer--close-button">
					<svg class="nm-icon-cancel">
						<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/cancel.svg#nm-icon-cancel"}}></use>
					</svg>
				</div>
			</div>
			<div class="modal-layer-headline-wrap" data-type="confirm">
				<svg class="nm-icon-confirmation-large modal-layer-icon-confirmation">
					<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/confirmation-large.svg#nm-icon-confirmation-large"}}></use>
				</svg>
				<p class="modal-layer-headline audi-headline-order-3">{{=window.i18n['sc.aoz.added.headline']}}</p>
			</div>
		</div>
		<div class="modal-layer__inner-content">
			<div class="modal-layer-message">
				<p class="audi-copy-m">{{=window.i18n['sc.aoz.added.message']}}</p>
				<button class="modal-layer-confirm-button audi-button"><span class="audi-button__text">{{=window.i18n['sc.aoz.added.ok']}}</span></button>
				<button class="aoz-product-added-contact-handler audi-button audi-button--ghost js-modal-layer-link" data-layer-type="IframePostModalLayerElement"><span class="audi-button__text">{{=window.i18n['sc.details.aoz.dealerbutton']}}</span></button>
			</div>
		</div>
	</div>
`;

export {exports as aozProductAddedNotificationModalLayerTemplate};
