export default class LiteReservationRequestController {
	static get defaults() {
		return {
			REQUEST_OPTIONS: {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				},
				cache: 'no-cache'
			}
		};
	}

	/**
	 * fetchCarDesireUrl
	 * @param {string} url url
	 * @param {object} postBody post body
	 * @returns {Promise<*>} fetch Data
	 */
	static async fetchLiteReservationUrl(url, postBody) {
		return this._fetchData(url,
			{
				...LiteReservationRequestController.defaults.REQUEST_OPTIONS,
				method: 'POST',
				body: JSON.stringify(postBody),
				mode: 'cors'
			});
	}

	/**
	 * generic fetch with error handling and parsing
	 * @param {string} url url to fetch data from
	 * @param {object} options_ request options
	 * @returns {Promise<*>} parsed result or handled error
	 */
	static async _fetchData(url, options_ = {}) {
		const requestOptions = Object.assign({}, LiteReservationRequestController.defaults.REQUEST_OPTIONS, options_);
		const rawResponse = await fetch(url, requestOptions);
		return LiteReservationRequestController._handleResponse(rawResponse);
	}

	/**
	 * middleware to preflight response/errors returnung parse response or custom Error
	 * @param {object} rawResponse response object
	 * @returns {Promise<*>} parsed response or custom Error Object
	 */
	static async _handleResponse(rawResponse) {
		try {
			const status = rawResponse.status;
			const response = await this._extractResponse(rawResponse);
			const error = new Error();

			if (status === 200 || status === 201) {
				return response;
			}
			else {
				error.message = 'unhandled error';
				throw error;
			}
		}
		catch (error) {
			return LiteReservationRequestController._handleErrors(error);
		}
	}

	/**
	 * extract response
	 * @param {object} rawResponse_ response object
	 * @returns {Promise<*>} parsed response or custom Error Object
	 */
	static async _extractResponse(rawResponse_) {
		const response = await rawResponse_.text().catch(() => {
			return rawResponse_;
		});
		return response;
	}

	/**
	 * handle response error middleware
	 * @param {Error} error_ custom Error
	 * @returns {Promise<*>} Error
	 */
	static async _handleErrors(error_) {
		throw error_;
	}
}
