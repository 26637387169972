const exports = `
	<div class="modal-layer__inner">
		<div class="modal-layer--close">
			<div class="modal-layer--close-button">
				<svg class="nm-icon-cancel">
					<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/cancel.svg#nm-icon-cancel"}}></use>
				</svg>
			</div>
		</div>	
		<div class="modal-layer__inner-content">
			<div class="sc-aoz-layer" data-layer-options='{"addCssClass": "sc-aoz-layer-options"}'>
				<div class="sc-aoz-layer-image-sizer">
					<div class="sc-aoz-layer-image-wrapper">
						{{? it.imageUrl}}
							<img class="sc-aoz-layer-image" src="{{=it.imageUrl}}">
						{{??}}
							<svg class="sc-aoz-layer-fallback-image" viewBox="0 0 1440 1440">
								<use xlink:href={{=SETUPS.get('stockcar.url.assets')+"/fallback/aud-vtp-fallback-1-1-logo.svg#aud-vtp-fallback-1-1-logo"}}></use>
							</svg>
						{{?}}
					</div>
				</div>
				<div class="sc-aoz-layer-text-wrapper">
					{{? it.productName}}
						<h2 class="sc-aoz-layer-title audi-headline-order-2">{{=it.productName}}</h2>
					{{?}}
					{{? it.differentiatingFeature}}
						<p class="sc-aoz-layer-differentiatingfeature audi-copy-m">{{=it.differentiatingFeature}}</p>
					{{?}}
					{{? it.productPrice}}
						<p class="sc-aoz-layer-price-wrapper"><span class="sc-aoz-layer-price audi-copy-m">{{=window.i18n['sc.details.aoz.listprice'] || 'sc.details.aoz.listprice'}}*: {{=it.productPrice}} {{=it.priceCurrencyConsumer}}</span><span class="sc-aoz-layer-price-vat audi-copy-s">{{=window.i18n['sc.details.aoz.layer.vat'] || 'sc.details.aoz.layer.vat'}}</span></p>
					{{?}}
	
					{{? it.description}}
						<div class="sc-aoz-layer-description audi-copy-m">{{=it.description}}</div>
					{{?}}
					<p class="sc-aoz-layer-price-vatdisclaimer audi-copy-s">*{{=window.i18n['sc.details.aoz.layer.vatdisclaimer'] || 'sc.details.aoz.layer.vatdisclaimer'}}</p>
					</div>
			</div>
		</div>
	</div>
`;

export {exports as aozProductInfoModalLayerTemplate};
