import {dom} from 'core-utils';
import {CoreEvents} from '@audi/audi-core-events';
import {globalEventEmitter} from "../stockcars-bundle";


var __ = {},
	exports = {
		'__': __
	};

__.oDefaults = {
	iDefaultRadix: 10,
	sClassAnimL2r: 'l2r',
	sClassAnimR2l: 'r2l',
	sClassPrev: 'prev',
	sClassNext: 'next',
	sSelectorStageImageWrapper: '.stage-image-wrapper',
	sSelectorStageImage: '.stage-image',
	sSelectorStageItem: '.stage-item',
	sSelectorStageNaviPrev: '.stage-navi-prev',
	sSelectorStageNaviNext: '.stage-navi-next'
};

__.stageInstances = {};

__.globalEventBus = globalEventEmitter;
__.domDelegate = dom.getEventDelegate('body');

__.initStageModules = function (data) { // eslint-disable-line max-statements
	var context, stageModulesOnPage, newStage, stageId, i;

	if (!!data && !!data.domElement) {
		context = data.domElement;
	}
	else if (!!data && !!data.element) {
		context = data.element;
	}
	else {
		context = document;
	}

	stageModulesOnPage = dom.getElementsArray('.sc-md-details-stage', context);

	for (i = 0; i < stageModulesOnPage.length; i++) {
		stageId = stageModulesOnPage[i].getAttribute('id');
		if (!__.stageInstances[stageId]) {
			newStage = new __.Stage(stageModulesOnPage[i]);
			newStage.id = stageId;
			newStage.delegate = dom.getEventDelegate('#' + newStage.id);
			newStage.selectorPrefix = '.' + newStage.id + ' ';
			__.stageInstances[stageId] = newStage;
			__.stageInstances[stageId].addStageEvents();
		}
		__.stageInstances[stageId].resetStage();
	}
};

__.addEvents = function () {
	__.globalEventBus.on(CoreEvents.PAGE_LOADED, __.initStageModules);
	__.globalEventBus.on(CoreEvents.LAYER_LOADED, __.initStageModules);
};

__.updateSelectionWrapper = function (context, event, instance) {
	return (function () {
		instance.handleUpdateSelection(context, event, instance);
	}());
};
__.arcClickWrapper = function (context, event, instance) {
	return (function () {
		instance.handleArcClick(context, event);
	}());
};
__.dotClickWrapper = function (context, event, instance) {
	return (function () {
		instance.handleDotClick(context, event);
	}());
};

__.Stage = function (stageElement) {
	this.context = stageElement;
};
__.Stage.prototype.addStageEvents = function () {
	var stageInstance = this, context;
	__.domDelegate.on('click', this.selectorPrefix + __.oDefaults.sSelectorStageNaviPrev, function () {
		context = this;
		event.stopPropagation();
		return __.arcClickWrapper(context, event, stageInstance);
	});
	__.domDelegate.on('click', this.selectorPrefix + __.oDefaults.sSelectorStageNaviNext, function () {
		context = this;
		event.stopPropagation();
		return __.arcClickWrapper(context, event, stageInstance);
	});
	__.domDelegate.on('change', this.selectorPrefix + '.stage-toggle', function () {
		context = this;
		return __.updateSelectionWrapper(context, event, stageInstance);
	});
	__.domDelegate.on('click', this.selectorPrefix + '.stage-toggle-label', function () {
		context = this;
		event.stopPropagation();
		return __.dotClickWrapper(context, event, stageInstance);
	});
	__.domDelegate.on('mousedown', this.selectorPrefix + __.oDefaults.sSelectorStageImageWrapper, __.activateSwipe);
	__.domDelegate.on('touchstart', this.selectorPrefix + __.oDefaults.sSelectorStageImageWrapper, __.activateSwipe);
	__.domDelegate.on('mouseup', __.deactivateSwipe);
	__.domDelegate.on('touchend', __.deactivateSwipe);
	this.swipeActive = false;
};

__.Stage.prototype.resetStage = function () {
	this.prev = 1;
};
/**
 * handle selection updates/changes
 * @param {Stage} eventContext - stage instance
 * @return {void}
 */
__.Stage.prototype.handleUpdateSelection = function (eventContext) {
	var i,
		imageWrappersCount,
		imageWrappers = dom.getElementsArray(this.selectorPrefix + __.oDefaults.sSelectorStageImageWrapper);

	for (i = 0, imageWrappersCount = imageWrappers.length; i < imageWrappersCount; i++) {
		imageWrappers[i].classList.remove(__.oDefaults.sClassAnimR2l);
		imageWrappers[i].classList.remove(__.oDefaults.sClassAnimL2r);
	}

	this.resetPrevNext(parseInt(eventContext.value, __.oDefaults.iDefaultRadix));

	if (this.arcNavi) {
		this.handleArcNaviTransition(eventContext.value);
	}
	else {
		this.handleDotNaviTransition(eventContext.value);
	}

	if (eventContext.value !== this.prev) {
		this.prev = parseInt(eventContext.value, __.oDefaults.iDefaultRadix);
	}
};
__.Stage.prototype.handleArcClick = function (eventContext, event) {
	event.stopPropagation();
	this.arcNavi = true;
};

__.Stage.prototype.handleDotClick = function (eventContext, event) {
	event.stopPropagation();
	this.arcNavi = false;
};

__.Stage.prototype.handleArcNaviTransition = function (index) {
	var imageWrapperSelector = this.selectorPrefix + __.oDefaults.sSelectorStageItem + '-' + index + ' ' + __.oDefaults.sSelectorStageImageWrapper;

	if ((this.prev > index && Math.abs(index - this.prev) === 1) || parseInt(index, __.oDefaults.iDefaultRadix) !== 1 && Math.abs(index - this.prev) !== 1) {
		dom.getElement(imageWrapperSelector).classList.add(__.oDefaults.sClassAnimL2r);
	}
	else {
		dom.getElement(imageWrapperSelector).classList.add(__.oDefaults.sClassAnimR2l);
	}
};

__.Stage.prototype.handleDotNaviTransition = function (index) {
	var imageWrapperSelector = this.selectorPrefix + __.oDefaults.sSelectorStageItem + '-' + index + ' ' + __.oDefaults.sSelectorStageImageWrapper;

	dom.getElement(this.selectorPrefix + __.oDefaults.sSelectorStageItem + '-' + index + ' .prev').classList.remove(__.oDefaults.sClassPrev);
	dom.getElement(this.selectorPrefix + __.oDefaults.sSelectorStageItem + '-' + index + ' .next').classList.remove(__.oDefaults.sClassNext);
	dom.getElement(this.selectorPrefix + __.oDefaults.sSelectorStageItem + '-' + index + ' ' + __.oDefaults.sSelectorStageImage + '-' + this.prev).classList.add(__.oDefaults.sClassPrev);
	dom.getElement(this.selectorPrefix + __.oDefaults.sSelectorStageItem + '-' + index + ' .active ~ ' + __.oDefaults.sSelectorStageImage + '-' + this.prev).classList.add(__.oDefaults.sClassNext);

	if (this.prev < index) {
		dom.getElement(imageWrapperSelector).classList.add(__.oDefaults.sClassAnimR2l);
	}
	else {
		dom.getElement(imageWrapperSelector).classList.add(__.oDefaults.sClassAnimL2r);
	}
};

__.Stage.prototype.resetPrevNext = function (activeStageIndex) {
	this.resetPrevClass(activeStageIndex);
	this.resetNextClass(activeStageIndex);
};

__.Stage.prototype.resetPrevClass = function (activeStageIndex) {
	var prevItemSelector, prevItemIdx;
	var prevItemsSelector = __.oDefaults.sSelectorStageItem + '-' + activeStageIndex + ' ' + __.oDefaults.sSelectorStageImage + '.left';
	var activeItemSelector = __.oDefaults.sSelectorStageItem + '-' + activeStageIndex + ' ' + __.oDefaults.sSelectorStageImage + '.active';

	dom.getElementsArray(this.selectorPrefix + prevItemsSelector).forEach(function (itm) {
		itm.classList.remove('prev');
	});

	if (activeStageIndex > 1) {
		prevItemSelector = __.oDefaults.sSelectorStageItem + '-' + activeStageIndex + ' ' + __.oDefaults.sSelectorStageImage + '-' + (activeStageIndex - 1) + '.left';
	}
	else {
		prevItemIdx = dom.getElement(this.selectorPrefix + activeItemSelector).previousElementSibling.getAttribute('data-index');
		prevItemSelector = __.oDefaults.sSelectorStageItem + '-' + activeStageIndex + ' ' + __.oDefaults.sSelectorStageImage + '-' + prevItemIdx + '.left';
	}
	dom.getElement(this.selectorPrefix + prevItemSelector).classList.add('prev');

};

__.Stage.prototype.resetNextClass = function (activeStageIndex) {
	var nextItemSelector;
	var nextItemsSelector = __.oDefaults.sSelectorStageItem + '-' + activeStageIndex + ' ' + __.oDefaults.sSelectorStageImage + '.right';
	var activeItemSelector = __.oDefaults.sSelectorStageItem + '-' + activeStageIndex + ' ' + __.oDefaults.sSelectorStageImage + '.active';
	var maxItemIndex = parseInt(dom.getElement(this.selectorPrefix + activeItemSelector).previousElementSibling.getAttribute('data-index'), __.oDefaults.iDefaultRadix);

	dom.getElementsArray(this.selectorPrefix + nextItemsSelector).forEach(function (itm) {
		itm.classList.remove(__.oDefaults.sClassNext);
	});

	if (activeStageIndex === maxItemIndex) {
		nextItemSelector = __.oDefaults.sSelectorStageItem + '-' + activeStageIndex + ' ' + __.oDefaults.sSelectorStageImage + '-1.right';
	}
	else {
		nextItemSelector = __.oDefaults.sSelectorStageItem + '-' + activeStageIndex + ' ' + __.oDefaults.sSelectorStageImage + '-' + (activeStageIndex + 1) + '.right';
	}
	dom.getElement(this.selectorPrefix + nextItemSelector).classList.add('next');
};

__.activateSwipe = function (e) {
	if (e.type === 'touchstart') {
		__.swipeX = e.originalEvent.pageX;
	}
	else {
		__.swipeX = e.pageX;
	}
	__.domDelegate.on('mousemove', __.swipe);
	__.domDelegate.on('touchmove', __.swipe);
};

__.deactivateSwipe = function () {
	__.domDelegate.off('mousemove', __.swipe);
	__.domDelegate.off('touchmove', __.swipe);
};

__.swipe = function (e) {
	var pos;
	if (e.type === 'touchmove') {
		pos = e.originalEvent.pageX;
	}
	else {
		pos = e.pageX;
	}
	if (pos >= __.swipeX + 5) {
		__.deactivateSwipe(e);
		dom.getElement(__.oDefaults.sSelectorStageNaviPrev, dom.closest(e.target, '.stage-item')).click();
	}
	else if (pos <= __.swipeX - 5) {
		__.deactivateSwipe(e);
		dom.getElement(__.oDefaults.sSelectorStageNaviNext, dom.closest(e.target, '.stage-item')).click();
	}
};

// initialize module ====
__.addEvents();
__.initStageModules();

export {exports as scDetailStage};

