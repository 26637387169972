import {BaseButtonLogic} from '../../stockcars-bundle';
import {STCK_STORE, SELECTORS} from '@oneaudi/stck-store';
import {OBJECT_UTILS, scPage} from '../../stockcars-utils-bundle';
import {detailsCarinfoButtonsTemplate} from './details-carinfo-buttons-template';
import {template} from 'core-utils';
import {appEvents} from 'core-application';
import RequestController from '../../stockcars/filter/scs-request-controller';

export class DetailsCarinfoButtonsElement extends BaseButtonLogic {
	constructor() {
		super();
		this._addEvents();
	}

	_addEvents() {
		this.unsubscribeAozState = STCK_STORE.observeStateFromStore(SELECTORS.AOZ.getAozStateRaw, this._reInitializeForAozVehicle.bind(this));
	}

	disconnectedCallback() {
		this.unsubscribeAozState();
	}

	render() {
		this._getCarinfoButtons().then((templateData) => {
			this.innerHTML = template.render(detailsCarinfoButtonsTemplate, templateData);
			this._dispatchEvent();
		});

	}

	_dispatchEvent() {
		const contentRendered = new CustomEvent(appEvents.CONTENT_RENDERED, {
			detail: {
				context: this,
				element: this
			}
		});
		document.dispatchEvent(contentRendered);
	}

	_reInitializeForAozVehicle() {
		this._initialize();
	}

	async _getCarinfoButtons() {
		let buttonsObject = {};

		buttonsObject.desktop = await this._getCarinfoButtonArray('desktop');
		buttonsObject.mobile = await this._getCarinfoButtonArray('mobile');
		return buttonsObject;
	}

	_getSpecificSpecialLink(ctaSpecialLinks_ = {}) {
		let link = ctaSpecialLinks_.contact || {};
		if (ctaSpecialLinks_.nws && this.data.vehicleData.nationWideSelling) {
			link = ctaSpecialLinks_.nws;
		}
		else if (ctaSpecialLinks_.aoz && ctaSpecialLinks_.aoz.method === 'POST' && this.data.aoz) {
			link = ctaSpecialLinks_.aoz;
		}
		return link;
	}

	_addPhoneButton(primary_ = false) {
		let phone = {
			'text': window.i18n['sc.details.dealer-phone.label'] || ' ',
			'phone': this.data.phone,
			'type': 'phone'
		};

		if (primary_) {
			phone.primary = true;
		}
		return phone;
	}

	_getButtonType() {
		let type = 'contact';
		if (this.data.nws) {
			type = 'nws';
		}
		else if (this.data.aoz) {
			type = 'aoz';
		}
		return type;
	}

	async _getCarinfoButtonArray(type_ = 'desktop') {
		let buttonArray = [];
		let specialLinks = OBJECT_UTILS.deepCopy(this.data.ctaSpecialLinks);

		if (this.data.leasing && specialLinks.leasing) {
			buttonArray = this._getLeasingButtonData(buttonArray, specialLinks, type_);
		}
		else if (this.data.ecom && specialLinks.contact) {
			buttonArray = this._getEcomButtonData(buttonArray, specialLinks, type_);
		}
		else {
			buttonArray = this._getContactButtonData(buttonArray, specialLinks, type_);
		}
		if (this.data.liteReservation && specialLinks.liteReservation) {
			const liteReservation = this._getLiteReservationButton(specialLinks);
			buttonArray.push({...specialLinks.liteReservation, ...liteReservation});
		}
		specialLinks.custom.forEach(item => {
			buttonArray.push(Object.assign(item, {'type': 'custom'}));
		});
		return buttonArray;
	}

	_getLeasingButtonData(buttonArray_, specialLinks_, type_) {
		buttonArray_.push(Object.assign(specialLinks_.leasing, {'type': 'leasing', 'primary': true}));
		if (type_ === 'mobile') {
			buttonArray_.push(this._addPhoneButton());
		}
		if (specialLinks_.liveDemo && this.data.liveDemo) {
			buttonArray_.push(Object.assign(specialLinks_.liveDemo, {'type': 'liveDemo'}));
		}
		return buttonArray_;
	}

	_getLiteReservationButton() {
		return {
			'type': 'liteReservation',
			'pdfUrl': this.data.pdfUrl,
			'vehicleId': this.data.vehicleData.id
		};
	}

	_getEcomButtonData(buttonArray_, specialLinks_, type_) {
		buttonArray_.push(Object.assign(specialLinks_.ecom, {
			'type': 'ecom',
			'primary': true,
			'dataStoreUrl': this.data.vehicleData.vtpDataStoreUrl,
			'pdfUrl': this.data.pdfUrl,
			'vehicleId': this.data.vehicleData.id,
			'css': 'sc-j-layerLink'
		}));
		if (type_ === 'mobile' && this.data.nws === false) {
			buttonArray_.push(this._addPhoneButton());
		}
		const secondaryButton = Object.assign(this._getSpecificSpecialLink(specialLinks_), {'type': this._getButtonType()});
		if (secondaryButton.type === 'contact') {
			secondaryButton.postData = scPage.createPostFormData();
		}
		buttonArray_.push(secondaryButton);
		if (specialLinks_.liveDemo && this.data.liveDemo) {
			buttonArray_.push(Object.assign(specialLinks_.liveDemo, {'type': 'liveDemo'}));
		}
		return buttonArray_;
	}

	_getContactButtonData(buttonArray_, specialLinks_, type_) {
		if (specialLinks_ && specialLinks_.contact) {
			if (type_ === 'desktop') {
				buttonArray_.push(Object.assign(this._getSpecificSpecialLink(specialLinks_), {
					'type': this._getButtonType(),
					'primary': true,
					postData: scPage.createPostFormData()
				}));
			}
			if (type_ === 'mobile') {
				if (this.data.vehicleData.nationWideSelling === false) {
					buttonArray_.push(this._addPhoneButton(true));
				}
				buttonArray_.push(Object.assign(this._getSpecificSpecialLink(specialLinks_), {'type': this._getButtonType(), postData: scPage.createPostFormData()}));
			}
			if (specialLinks_.liveDemo && this.data.liveDemo) {
				buttonArray_.push(Object.assign(specialLinks_.liveDemo, {'type': 'liveDemo'}));
			}
		}
		return buttonArray_;
	}


}

if (window.customElements.get('details-carinfo-buttons-element') === undefined) {
	window.customElements.define('details-carinfo-buttons-element', DetailsCarinfoButtonsElement);
}
