import {dom} from 'core-utils';
import {scroll} from 'core-global';

export default class DealerScrollElement extends HTMLElement {
	constructor() {
		super();
	}

	/**
	 * defaults
	 * @return {{classDetailContainer: string, classNavWrapper: string, classMobileLabels: string, classDetailCarInfo: string, classNoTransition: string}} default values - strings, selectors, attributes etc
	 */
	static get defaults() {
		return {
			'classDetailContainer': '.sc-detail-module',
			'classNavWrapper': '.sc-navigation-wrap',
			'classMobileLabels': '.sc-module[data-module="anchor-navigation-item"]',
			'classDetailCarInfo': '.fa-vtp-carinfo',
			'classFavorite': '.js-favorite',
			'classLayerLink': '.sc-j-layerLink',
			'classSwitchLabel': '.sc-visibility-switch-label.sc-mobile-label',
			'attributeAnchor': 'anchor-navigation-item',
			'classNoTransition': 'sc-no-transition',
			'idAnchorDefault': '#sc-progress-default'
		};
	}

	/**
	 * custom elements overwritten connected callback method
	 * @returns {void} void
	 */
	connectedCallback() {
		this._addEventListener();
	}

	/**
	 * _addEventListener - add event listener
	 * @returns {void} void
	 * @private
	 */
	_addEventListener() {
		this.addEventListener('click', this._dealerScrollHandler);
	}

	/**
	 * _getProperties - get properties - elements and attributes
	 * @returns {void} void
	 * @private
	 */
	_getProperties() {
		this._scrollTarget = this.getAttribute('data-scroll');
		this._mobileLabels = dom.getElement(DealerScrollElement.defaults.classMobileLabels);
		this._navBar = dom.getElement(DealerScrollElement.defaults.classNavWrapper);
		this._offset = dom.isElement(this._navBar) ? this._navBar.clientHeight : 0;
		this._mobileLabelOffset = this._mobileLabels ? this._mobileLabels.clientHeight : 0;
		this._detailModules = dom.getElementsArray(DealerScrollElement.defaults.classDetailContainer);
	}

	/**
	 * ScrollHandler scroll to element defined in  data attribute
	 * @return {void} returns nothing
	 */
	_dealerScrollHandler() {
		this._getProperties();
		this._setDealerTabChecked(this._detailModules, this._scrollTarget);
		scroll.scrollToElementWithDistance(`.${this._scrollTarget}`, this._offset + this._mobileLabelOffset);
		this._detailModules.forEach(module => {
			module.classList.remove(DealerScrollElement.defaults.classNoTransition);
		});
	}

	/**
	 * setDealerTabChecked
	 * @param {Array} detailModules_ array of detail modules
	 * @param {String} anchor_ string of anchor element
	 * @return {void} returns nothing
	 */
	_setDealerTabChecked(detailModules_, anchor_) {
		const dealerWrapper = document.querySelector(`.${anchor_}`).previousElementSibling;

		if (dealerWrapper && dealerWrapper.getAttribute('data-module') === DealerScrollElement.defaults.attributeAnchor) {
			const anchorNaviClosed = document.querySelector('input[id="' + DealerScrollElement.defaults.idAnchorDefault + '"]');
			const dealerLabel = dealerWrapper.querySelector(DealerScrollElement.defaults.classSwitchLabel);
			detailModules_.forEach(module => {
				module.classList.add(DealerScrollElement.defaults.classNoTransition);
			});
			anchorNaviClosed.checked = true;
			const inputId = dealerLabel.getAttribute('for');
			document.querySelector('input[id="' + inputId + '"]').checked = true;

		}
	}
}

if (window.customElements.get('dealer-scroll-element') === undefined) {
	window.customElements.define('dealer-scroll-element', DealerScrollElement);
}


