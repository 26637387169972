import {modalLayer, ModalLayerContentBase} from "core-application";
import {aozProductInfoModalLayerTemplate} from './aoz-product-info-modal-layer-template';
import {template} from "core-utils";

export default class AozProductInfoModalLayerElement extends ModalLayerContentBase {

	constructor(data_ = {}) {
		super();
		this._bindContextToFunctions();
		this.data = data_;
	}

	/**
	 * static getter function for default CSS Selectors
	 * @static
	 * @returns {object <{closeButton: string, confirmButton: string}>} defaults defaults strings
	 */
	static get defaults() {
		return {
			closeButton: '.modal-layer--close-button'
		};
	}

	/**
	 * type - getter for type
	 * @returns {string} type string
	 */
	static get type() {
		return 'AozProductInfoModalLayerElement';
	}

	/**
	 * getContent
	 * @param {object} data_ data
	 * @returns {Promise<ModalConsumptionEmissionLayerElement>} modal consumption layer element
	 */
	static async getContent(data_) {
		return new AozProductInfoModalLayerElement(data_);
	}

	/**
	 * connectedCallback
	 * @returns {void} returns nothing
	 */
	connectedCallback() {
		this._render();
		this.addEvents();
	}

	/**
	 * disconnectedCallback
	 * @returns {void} returns nothing
	 */
	disconnectedCallback() {
		this.removeEvents();
	}

	/**
	 * _bindContextToFunctions - bind 'this' context to necessary functions
	 * @returns {void} returns nothing
	 */
	_bindContextToFunctions() {
		this._closeLayer = this._closeLayer.bind(this);
		this._render = this._render.bind(this);
	}

	/**
	 * addEvents
	 * @returns {void} returns nothing
	 */
	addEvents() {
		this.closeButton.addEventListener('click', this._closeLayer);
	}

	/**
	 * removeEvents
	 * @returns {void} returns nothing
	 */
	removeEvents() {
		this.closeButton.removeEventListener('click', this._closeLayer);
	}

	/**
	 * render
	 * @returns {void} returns nothing
	 */
	_render() {
		this.innerHTML = template.render(aozProductInfoModalLayerTemplate, this.data);
		this.closeButton = this.querySelector(AozProductInfoModalLayerElement.defaults.closeButton);
	}

	/**
	 * _closeLayer - closes modal layer
	 * @returns {void} void
	 * @private
	 */
	_closeLayer() {
		this._triggerClose();
	}
}

modalLayer.registerLayerType(AozProductInfoModalLayerElement);

if (window.customElements.get('aoz-product-info-modal-layer-element') === undefined) {
	window.customElements.define('aoz-product-info-modal-layer-element', AozProductInfoModalLayerElement);
}
