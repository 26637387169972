import {lazyLoad} from '../../stockcars-utils-bundle';
import {getDetailsPageVehicleData} from "../../stockcars-tracking/tracking-utils";

export default class DetailsEquipmentElement extends HTMLElement {
	constructor() {
		super();
	}

	get isOptionalDetailsEquipmentElement() {
		return this.classList.contains('sc-detail-oe-module');
	}

	get isEmpty() {
		return !this.querySelector('.sc-detail-oe-container');
	}

	async checkTyreLabelLink() {
		const allTyreLinks = this.querySelectorAll('.sc-tyre-label-link');
		if (allTyreLinks.length) {
			const vehicle = await getDetailsPageVehicleData();
			const {tyreLabels = []} = vehicle;
			const validTyreLabels = tyreLabels.filter(entry => !!entry.prNumber && (Array.isArray(entry.labels) && entry.labels.length));
			allTyreLinks.forEach(item => {
				if (validTyreLabels.length === 0) {
					item.classList.add('nm-hidden');
				}
			});
		}
	}

	/**
	 * connectedCallback
	 * @returns {void} void
	 */
	connectedCallback() {
		lazyLoad.register(this, {padding: '500px'});
		if (this.isOptionalDetailsEquipmentElement && this.isEmpty) {
			this._hideComponent();
		}
		else {
			this.checkTyreLabelLink();
		}
	}

	/**
	 * hides this component
	 *
	 * @returns {void} none
	 */
	_hideComponent() {
		this.classList.add('sc-hidden');
	}
}

if (window.customElements.get('details-equipment-element') === undefined) {
	window.customElements.define('details-equipment-element', DetailsEquipmentElement);
}
