import {CoreEvents} from '@audi/audi-core-events';
import {stateRegistry} from 'microkernel';
import {STOCKCARS_VEHICLE_STORE_ID} from '../stockcars-microkernel-store-bundle';
import {scPage} from '../stockcars-utils-bundle';
import {globalEventEmitter, vehiclesController} from '../stockcars-bundle';

class ScDetailsVehicleStore {

	constructor() {
		this._globalEventBus = globalEventEmitter;
		this._addEventListener();
		this._updateMicrokernelVehcileStore();
	}

	_addEventListener() {
		this._updateMicrokernelVehcileStore = this._updateMicrokernelVehcileStore.bind(this);
		this._globalEventBus.on(CoreEvents.PAGE_LOADED, this._updateMicrokernelVehcileStore);
		this._globalEventBus.on(CoreEvents.LAYER_LOADED, this._updateMicrokernelVehcileStore);
	}

	async _updateMicrokernelVehcileStore() {
		const vehicleId = scPage.getVehicleIdFromUrl();
		if (scPage.isDetailsPage(location.href) && vehicleId) {
			const vehicleData = await this._getVehicleData(vehicleId);
			stateRegistry.triggerAction(STOCKCARS_VEHICLE_STORE_ID, 'update', {
				vehicle: vehicleData
			});
		}
	}

	async _getVehicleData(vehicleId_ = '') {
		if (vehicleId_) {
			return await vehiclesController.loadVehicleById(vehicleId_).catch(error => console.error(error));
		}
	}
}

const scDetailsVehicleStore = new ScDetailsVehicleStore();

export {scDetailsVehicleStore, ScDetailsVehicleStore};
