import {BaseButtonLogic} from '../../stockcars-bundle';
import {STCK_STORE, SELECTORS} from '@oneaudi/stck-store';
import {OBJECT_UTILS, scPage} from '../../stockcars-utils-bundle';
import {detailsDealerinfoButtonsTemplate} from './details-dealer-info-buttons-template';
import {template} from 'core-utils';
import {appEvents} from "core-application";

export class DetailsDealerinfoButtonsElement extends BaseButtonLogic {
	constructor() {
		super();
		this._addEvents();
	}

	_addEvents() {
		this.unsubscribeAozState = STCK_STORE.observeStateFromStore(SELECTORS.AOZ.getAozStateRaw, this._reInitializeForAozVehicle.bind(this));
	}

	disconnectedCallback() {
		this.unsubscribeAozState();
	}

	render() {
		const templateData = this._getDealerinfoButtons();
		this.innerHTML = template.render(detailsDealerinfoButtonsTemplate, templateData);
		this._dispatchEvent();
	}

	_dispatchEvent() {
		const contentRendered = new CustomEvent(appEvents.CONTENT_RENDERED, {
			detail: {
				context: this,
				element: this
			}
		});
		document.dispatchEvent(contentRendered);
	}

	_reInitializeForAozVehicle() {
		this._initialize();
	}

	_getDealerinfoButtons() {
		let buttonsObject = {};
		buttonsObject.desktop = this._getDealerinfoButtonArray('desktop');
		buttonsObject.mobile = this._getDealerinfoButtonArray('mobile');
		return buttonsObject;
	}

	_getSpecificSpecialLink(ctaSpecialLinks_ = {}) {
		let link = ctaSpecialLinks_.contact;
		if (ctaSpecialLinks_.nws && this.data.vehicleData.nationWideSelling) {
			link = ctaSpecialLinks_.nws;
		}
		else if (ctaSpecialLinks_.aoz && ctaSpecialLinks_.aoz.method === 'POST' && this.data.aoz) {
			link = ctaSpecialLinks_.aoz;
		}
		return link || {};
	}

	_addPhoneButton(primary_ = false) {
		let phone = {
			'text': window.i18n['sc.details.dealer-phone.label'] || ' ',
			'phone': this.data.phone,
			'type': 'phone'
		};

		if (primary_) {
			phone.primary = true;
		}
		return phone;
	}

	_getButtonType() {
		let type = 'contact';
		if (this.data.nws) {
			type = 'nws';
		}
		else if (this.data.aoz) {
			type = 'aoz';
		}
		return type;
	}

	_getDealerinfoButtonArray(type_ = 'desktop') {
		let buttonArray = [];
		let specialLinks = OBJECT_UTILS.deepCopy(this.data.ctaSpecialLinks);
		// LEASING
		if (this.data.hasFinancing && this.data.vehicleData.leasingCar && specialLinks && specialLinks.leasing && specialLinks.contact) {
			if (type_ === 'mobile' && this.data.nws === false) {
				buttonArray.push(this._addPhoneButton(true));
			}
		}
		else {
			if (type_ === 'desktop') {
				buttonArray.push(Object.assign(this._getSpecificSpecialLink(specialLinks), {'type': this._getButtonType(), 'primary': true, postData: scPage.createPostFormData()}));
			}
			if (type_ === 'mobile') {
				if (this.data.vehicleData.nationWideSelling === false) {
					buttonArray.push(this._addPhoneButton(true));
					buttonArray.push(Object.assign(this._getSpecificSpecialLink(specialLinks), {'type': this._getButtonType(), postData: scPage.createPostFormData()}));
				}
				else {
					buttonArray.push(Object.assign(this._getSpecificSpecialLink(specialLinks), {'type': this._getButtonType(), 'primary': true, postData: scPage.createPostFormData()}));
				}
			}
		}
		return buttonArray;
	}

}
if (window.customElements.get('details-dealerinfo-buttons-element') === undefined) {
	window.customElements.define('details-dealerinfo-buttons-element', DetailsDealerinfoButtonsElement);
}
