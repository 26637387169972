import {
	contactButtonTemplate,
	customButtonTemplate,
	ecomButtonTemplate,
	leasingButtonTemplate,
	liveDemoButtonTemplate,
	phoneButtonTemplate,
	liteReservationButtonTemplate,
	reservationButtonTemplate
} from '../../stockcars-bundle';

const exports = `
	<div class="sc-details-carinfo-buttons-desktop">
		{{~it.desktop :item}}
			{{? item.type ==="contact" || item.type ==="aoz" || item.type ==="nws"}}
				${contactButtonTemplate}
			{{?? item.type === "phone"}}
				${phoneButtonTemplate}
			{{?? item.type === "ecom"}}
				${ecomButtonTemplate}
			{{?? item.type === "leasing"}}
				${leasingButtonTemplate}
			{{?? item.type === "liveDemo"}}
				${liveDemoButtonTemplate}
			{{?? item.type === "liteReservation"}}
				${liteReservationButtonTemplate}
			{{?? item.type === "reservation"}}
				${reservationButtonTemplate}
			{{?? item.type === "custom"}}
				${customButtonTemplate}
			{{?}}
		{{~}}
	</div>
	<div class="sc-details-carinfo-buttons-mobile">
		{{~it.mobile :item}}
			{{? item.type === "contact" || item.type === "aoz" || item.type ==="nws"}}
				${contactButtonTemplate}
			{{?? item.type === "phone"}}
				${phoneButtonTemplate}
			{{?? item.type === "ecom"}}
				${ecomButtonTemplate}
			{{?? item.type === "leasing"}}
				${leasingButtonTemplate}
			{{?? item.type === "liveDemo"}}
				${liveDemoButtonTemplate}
			{{?? item.type === "liteReservation"}}
				${liteReservationButtonTemplate}
			{{?? item.type === "reservation"}}
				${reservationButtonTemplate}
			{{??}}
				${customButtonTemplate}
			{{?}}
		{{~}}
	</div>
`;

export {exports as detailsCarinfoButtonsTemplate};
